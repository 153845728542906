import { useState } from "react";

export default (props) => {
  return (
    <div>
      <textarea
        value={props?.value}
        onChange={props?.onChange}
        maxLength={props?.maxLength}
        placeholder={props?.placeholder}
        style={{
          display: "flex",
          minWidth: "300px",
          minHeight: "150px",
          padding: "8px",
          textAlign: "justify",
          resize: "none",
          overflowWrap: "break-word",
          wordBreak: "break-word",
          border: "1px solid #ccc",
          borderRadius: "4px",
          ...props?.style,
        }}
      />
    </div>
  );
};
