import { useEffect, useState } from "react";

import api from "service";
import { ShowPage } from "context/showPage";
import { ShowPopup } from "context/popup";
import AddView from "components/web/view/add";
import PopUp from "components/web/popup";

import Theme from "theme";


export default (props) => {
  const { PageInfo, GoPage } = ShowPage();
  const { PopupShow, PopupClose } = ShowPopup();

  //Varieavel que vai receber o switch
  const [typeProvider, setTypeProvider] = useState(false);

  //Nome cnpj Provider
  const [employeeNameProvider, setEmployeeNameProvider] = useState("");

  //Busa cep provider
  const [addrStateProvider, setAddrStateProvider] = useState("");
  const [addrCityProvider, setAddrCityProvider] = useState("");
  const [addrDistrictProvider, setAddrDistrictProvider] = useState("");
  const [addrStreetProvider, setAddrStreetProvider] = useState("");

  useEffect(() => {}, []);

  const [typePix, setTypePix] = useState("");

  const inputStyle = {
    // maxHeight: 140,
    input: {
      border: "1px solid #bfbfbf",
      borderRadius: 4,
      padding: 4,
      fontSize: "1.5vh",
      maxWidth: "100%",
    },
    title: Theme.text.input.title,
    select: {
      border: "1px solid #bfbfbf",
      borderRadius: 4,
      padding: 4,
      maxHeight: 32,
      fontSize: "2vh"
    },
  };

  useEffect(async () => {
    // GoPage("ListClient");
    // GoPage("AddClient");
    // GoPage("ListEmployee");
  }, []);

  //Adicionar parceiros
  return {
    component: "container",
    style: {
      display: PageInfo.show === "management-add" ? "flex" : "none",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      width: "100%",
      maxHeight: "100vh",
      overflowX: "auto",
      overflowY: "auto",
      flex: 1,
      zIndex: 1,
    },
    children: [
      AddView({
        direction: "row",
        title: "Adicionar usuário",
        form: {
          // onSave: api..add,
          style: {
            main: {
              flexWrap: "wrap",
              overflow: "auto",
              maxWidth: "60%",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: 12,
              padding: "1%",
            },
            box: {
              1: {},
            },
            btn: {
              cancel: {},
              save: {},
              style: {
                maxWidth: "60%",
              },
            },
          },
          box: {
            1: {
              style: {
                padding: "2%",
                flex: 1,
                minWidth: "100%",
                display: "flex",
                flexDirection: "column",
                border: "1px solid #CBD5E1",
                borderRadius: 4,
                gap: 8,
              },
              input: [
                {
                  label: "Informações do Usuário",
                  style: {
                    fontSize: Theme.fontSizes.form.title,
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    color: Theme.colors.blue.life,
                    width: "100%",
                    display: "flex",
                    marginBottom: 4,
                    height: 16,
                  },
                },
                //divInput
                {
                  type: "div",
                  style: {
                    flex: 1,
                    flexDirection: "row",
                    alignItems: "flex-start",
                    gap: 24,
                    height: "100%",
                  },
                  children: [
                    {
                      type: "div",
                      style: {
                        display: "column",
                        gap: 8,
                      },
                      children: [
                        //Nome
                        {
                          id: "name",
                          label: "Nome Completo",
                          type: "input",
                          style: inputStyle,
                          required: true,
                          // check: { min: 5 },
                        },
                        //email
                        {
                          id: "email",
                          label: "Email ",
                          type: "input",
                          style: inputStyle,
                          required: true,
                        },
                        //Nivel de acesso
                        {
                          id: "access_level",
                          label: "Nível de acesso",
                          type: "select",
                          option: [
                            {
                              _id: 1,
                              name: "Admin",
                            },
                            {
                              _id: 2,
                              name: "Gestor",
                            },
                            {
                              _id: 3,
                              name: "Operacional",
                            },
                          ],
                          style: {
                            ...inputStyle,
                          },
                          required: true,
                        },                        
                      ],
                    },
                    {
                      type: "div",
                      style: {
                        display: "flex",
                        display: "column",
                      },
                      children: [
                        //Senha
                        {
                          id: "password",
                          label: "Senha",
                          type: "input",
                          style: inputStyle,
                          required: true,
                          // check: { min: 5 },
                        },
                        //Confirmar senha
                        {
                          id: "confirmPassword",
                          label: "Confirmar Senha",
                          type: "input",
                          style: inputStyle,
                          required: true,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          },
        },
        showPopup: false,
      }),
    ],
  };
};
