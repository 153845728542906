import { Render } from "@8_dev/sjson-web";
import Theme from "theme";
import api from "service";
// import { Render } from "render";

import { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import PopUp from "components/web/popup";
import { Context } from "context/data";
import { ShowPage } from "context/showPage";
import { ShowPopup } from "context/popup";

// uso dessa pagina
import Logo from "assets/images/main-logo1.png";
import Bcg from "assets/images/bcg.jpg";
import Input from "components/web/input";

import Login from "pages/access";
import Intro from "pages/intro";

let Page = () => {
  const { Data, Set } = Context();

  useEffect(async () => {}, []);

  return Render({
    component: "container",
    style: {
      width: "100vw",
      height: "100vh",
      maxWidth: "100vw",
      maxHeight: "100vh",
      minWidth: "100vw",
      minHeight: "100vh",
      position: "absolute",
      overflow: "hidden",
      backgroundColor: Theme.colors.gray.light,
    },
    children: [
      // Conteudo das Paginas
      PopUp(),
      Login(),
      {
        render: (
          <div
            style={{
              position: "absolute",
              display: Data.user?.status ? "flex" : "none",
            }}
          >
            <Fade>
              {Render({
                component: "container",
                style: {
                  width: "100vw",
                  height: "100vh",
                  position: "absolute",
                  // backgroundColor: Theme.colors.black,
                },
                children: [Intro()],
              })}
            </Fade>
          </div>
        ),
      },
    ],
  });
};

export default Page;
