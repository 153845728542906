import { useState } from "react";
import Theme from "theme";

export default (props) => {
  return {
    component: "button",
    style: {
      ...Theme.button?.default?.box,
      cursor: "pointer",
      transition: "transform 0.1s ease",
      ...props?.style?.button,
    },
    onClick: async () => {
      if (props?.onPress)
        try {
          await props.onPress(props);
        } catch (err) {
          console.log("button err", err);
        }

      // set Loading off
    },
    disabled: props?.disabled,
    children: props?.children
      ? props.children.map((item) => {
          return item;
        })
      : [
          {
            component: "text",
            text: props?.label || "",
            style: {
              ...Theme.button?.default?.label,
              ...props?.style?.label,
            },
          },
        ],
  };
};
