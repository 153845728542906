// Init Page para valida algumas infoamções e selcionar para qual pagina direcionar..

// verificar historico, ou url com parametros..

import { useEffect, useState } from "react";

import api from "service";
import { ShowPage } from "context/showPage";
import ListView from "components/web/view/list";
import PopUp from "components/web/popup";

import Theme from "theme";

// importar pagina padrao e criar variveis para add e listar...
// import AddClient from "pages/admin/client/add";
// import ListClient from "pages/admin/client/list";

export default (props) => {
  const { PageInfo, GoPage } = ShowPage();

  useEffect(async () => {
    // GoPage("ListClient");
    // GoPage("AddClient");
    // GoPage("ListEmployee");
  }, []);

  //Lista de usuários Marketing
  return {
    component: "container",
    style: {
      flex: 1,
      display: PageInfo.show === "marketing" ? "flex" : "none",
      // backgroundColor: "red",
    },
    children: [
      {
        component: "container",
        style: {
          width: "100%",
          flex: 1,
        },
        children: [
          ListView({
            id: "marketing-list",
            title: "Pushupus | Ativação",
            // exportFileName: 'user-marketing',
            // exportFileType: 'pdf,excel,csv'
            style: {
              cel: (props) => {
                // console.log( props.col , props.row )/
                switch (props.col) {
                  case "state": {
                    return {
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: 24,
                      maxWidth: "100%",
                      borderRadius: 4,
                      padding: 8,
                      fontSize: Theme.fontSizes.capitalized,
                      fontWeight: "600",
                      color:
                        props.item[props.col].code === 0
                          ? "rgba(75, 85, 99, 1)"
                          : props.item[props.col].code === 1
                          ? "rgba(5, 150, 105, 1)"
                          : props.item[props.col].code === 2
                          ? "rgba(249, 68, 28, 1)"
                          : "transparent",
                      backgroundColor:
                        props.item[props.col].code === 0
                          ? "rgba(229, 231, 235, 1)"
                          : props.item[props.col].code === 1
                          ? "rgba(167, 243, 208, 1)"
                          : props.item[props.col].code === 2
                          ? "rgba(255, 209, 183, 1)"
                          : "transparent",
                    };
                  }
                  case "access": {
                    return {
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: 24,
                      maxWidth: "90%",
                      borderRadius: 4,
                      padding: 8,
                      fontSize: Theme.fontSizes.capitalized,
                      fontWeight: "600",
                      color:
                        props.item[props.col].code === 0
                          ? "rgba(75, 85, 99, 1)"
                          : props.item[props.col].code === 1
                          ? "rgba(5, 150, 105, 1)"
                          : props.item[props.col].code === 2
                          ? "rgba(75, 85, 99, 1)"
                          : props.item[props.col].code === 3
                          ? "rgba(173, 195, 237, 0)"
                          : props.item[props.col].code === 4
                          ? "rgba(5, 89, 150, 1)"
                          : props.item[props.col].code === 5
                          ? "rgba(150, 109, 5, 1)"
                          : "transparent",
                      backgroundColor:
                        props.item[props.col].code === 0
                          ? "rgba(229, 231, 235, 1)"
                          : props.item[props.col].code === 1
                          ? "rgba(167, 243, 208, 1)"
                          : props.item[props.col].code === 2
                          ? "rgba(229, 231, 235, 1)"
                          : props.item[props.col].code === 3
                          ? "rgba(173, 195, 237, 0)"
                          : props.item[props.col].code === 4
                          ? "rgba(173, 195, 237, 1)"
                          : props.item[props.col].code === 5
                          ? "rgba(255, 209, 113, 1)"
                          : "transparent",
                    };
                  }
                  default:
                    return {
                      // fontSize: Theme.fontSizes.row,
                      // fontWeight: "400",
                      fontWeight: "normal",
                    };
                }
              },
              row: {
                width: "100%",
                fontWeight: "normal",
                display: "flex",
                fontSize: Theme.fontSizes.row,
                fontFamily: Theme.fontFamilies.primary,
                minHeight: 72,
                maxHeight: 72,
                alignItems: "center",
                justifyContent: "center",
                padding: 8,
              },
              col: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: Theme.fontSizes.col,
                borderRight: "none",
                fontWeight: "bold",
                padding: 8,
                minHeight: 32,
                maxHeight: 32,
              },
            },
            col: {
              id: { title: "ID", style: { flex: 0.1 } },
              name: {
                title: "Nome",
                style: {
                  flex: 2,
                },
              },
              message: {
                title: "Mensagem",
                style: {
                  flex: 2.5,
                  lineHeight: 1,
                },
              },
              filter_animal_category: {
                title: "Filtro-Pet",
                style: {
                  flex: 1,
                },
              },
              filter_address_state: {
                title: "Filtro-região",
                style: {
                  flex: 1,
                },
              },
              filter_situation: {
                title: "Filtro-situação",
                style: {
                  flex: 1,
                },
              },
              number_sent: {
                title: "N.° de envios",
                style: {
                  flex: 1,
                },
              },
            },
            item: api.marketing.pushup,
          }),
        ],
      },
    ],
  };
};
