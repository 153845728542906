import { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import { Render } from "@8_dev/sjson-web";

//api
import api from "service";

//context
import { ShowPage } from "context/showPage";
import { ShowPopup } from "context/popup";

//components/web
import ListView from "components/web/view/list";
import Button from "components/web/button";
import AddView from "components/web/view/add";
import Input from "components/web/input";

//images
import EditManagement from "assets/images/edit-management.png";

//pages

import Theme from "theme";

export default () => {
  const { PageInfo, GoPage, setUpdate } = ShowPage();
  const { PopupShow, PopupClose } = ShowPopup();
  const [popUp, setPopUp] = useState(false);
  const [selectedHours, setSelectedHours] = useState([]);

  const [item, setItem] = useState({});

  const [selectState, setSelectState] = useState(-1);

  const inputStyle = {
    // maxHeight: 140,
    input: {
      border: "1px solid #bfbfbf",
      borderRadius: 4,
      padding: 4,
      fontSize: "1.5vh",
      maxWidth: "100%",
    },
    title: Theme.text.input.title,
    select: {
      border: "1px solid #bfbfbf",
      borderRadius: 4,
      padding: 4,
      maxHeight: 32,
      fontSize: "2vh"
    },
  };

  return {
    component: "container",
    style: {
      flex: 1,
      display: PageInfo.show === "user-management" ? "flex" : "none",
    },
    children: [
      {
        component: "container",
        style: {
          width: "100%",
          flex: 1,
        },
        children: [
          {component: "container",
          style: {
            width: "227px",
            height: "44px",
            // position: "absolute",
            // top: "10rem",
            // marginLeft: "5rem",
          },
          children:[
            {
            component: "container",
            style: {
              ...Theme.button.action,
              backgroundColor: Theme.colors.blue.life,
              marginLeft: 16,
              marginBottom: 24,
              },
              onClick: async () => {
                GoPage("management-add");
                  // PopupShow({
                  //   title: "",
                  //   description: "",
                  //   style: {
                  //     box: {
                  //       width: "auto",
                  //       height: "auto",
                  //       alignItems: "center",
                  //     },
                  //     close: {
                  //       display: "none",
                  //     },
                  //   },
                  //   item:[
                  //     {
                  //       component: "container",
                  //       style:{
                  //         width: "500px",
                  //         height: "500px",
                  //         border: "1px solid red",
                  //       },
                  //       children:[
                  //       ],
                  //     },
                      
                  //   ],
                  //   buttons: [
                  //     {
                  //       component: "container",
                  //       style: {
                  //         display: "flex",
                  //         width: "100%",
                  //         alignItems: "center",
                  //         justifyContent: "center",
                  //         flexDirection: "column",
                  //         gap: 8,
                  //         marginTop: 12,
                  //       },
                  //       children: [
                  //         //btn fechar
                  //         Button({
                  //           label: "Fechar",
                  //           style: {
                  //             button: {
                  //               backgroundColor: "white",
                  //               width: "100%",
                  //               alignItems: "center",
                  //               height: "42%",
                  //               color: "blue",
                  //               borderRadius: 6,
                  //               border: "none",
                  //             },
                  //             label: {
                  //               fontFamily: Theme.fontFamilies.primary,
                  //               fontSize: 18,
                  //             },
                  //           },
                  //           onPress: async () => {
                  //             PopupClose();
                  //           },
                  //         }),
                  //       ],
                  //     },
                  //   ],
                  // });
                },
              children: [
                {
                component: "text",
                text: "Adicionar novo usuário",
                style: {
                  ...Theme.text.button,
                  fontSize: 14,
                  },
                },
              ],
            },
          ],
          },
          ListView({
            id: "user-management",
            title: "Gerenciar usuários",
            style: {
              cel: (props) => {
                switch (props.col) {
                  default:
                    return {
                      fontWeight: "normal",
                      // flex: 1.2,
                    };
                }
              },
              row: {
                width: "100%",
                fontWeight: "normal",
                display: "flex",
                fontSize: Theme.fontSizes.row,
                fontFamily: Theme.fontFamilies.primary,
                minHeight: 64,
                maxHeight: 64,
                alignItems: "center",
                justifyContent: "center",
                padding: 2,
              },
              col: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: Theme.fontSizes.col,
                borderRight: "none",
                fontWeight: "bold",
                padding: 8,
                minHeight: 32,
                maxHeight: 32,
              },
            },
            col: {
              name: {
                title: "Nome",
                style: {
                  flex: "0.5",
                },
              },
              email: {
                key: [],
                title: "E-mail",
              },
              last_access: {
                key: [],
                title: "último acesso",
                style: {},
              },
              acces_level: {
                key: [],
                title: "Nível de acceso",
              },
              edit: {
                  title: "Ações",
                  component: "container",
                  style:{
                    display: "flex",
                    border: "1px solid red",

                  },
                  children:[
                    
                    item: [
                      {
                        type: "image",
                        src: EditManagement,
                        size: 22,
                        style: {
                          display: "flex",
                          border: "none",
                          backgroundColor: "white",
                          alignItems: "center",
                          cursor: "pointer",
                        },
                        // onClick:
                        // GoPage("");
                      },
                    ],
                    item: [
                      {
                        type: "image",
                        src: EditManagement,
                        size: 22,
                        style: {
                          display: "flex",
                          border: "none",
                          backgroundColor: "white",
                          alignItems: "center",
                          cursor: "pointer",
                        },
                        // onClick:
                        // GoPage("");
                      },
                    ],
                  ]
              },
            },
            // item: api.,
          }),
        ],
      },
    ],
  };
};

