import { useEffect, useState } from "react";
import api from "service";
import { ShowPage } from "context/showPage";
import AddView from "components/web/view/add";
import ListView from "components/web/view/list";
import PopUp from "components/web/popup";
import { ShowPopup } from "context/popup";

import * as XLSX from "xlsx";

import Theme from "theme";

// importar pagina padrao e criar variveis para add e listar...
// import AddClient from "pages/admin/client/add";
// import ListClient from "pages/admin/client/list";

export default (props) => {
  const { PageInfo, GoPage } = ShowPage();
  const { PopupShow, PopupClose } = ShowPopup();

  //Nome cnpj Business
  const [businessName, setBusinessName] = useState("");

  //Buca cep business
  const [addrState, setAddrState] = useState("");
  const [addrCity, setAddrCity] = useState("");
  const [addrDistrict, setAddrDistrict] = useState("");
  const [addrStreet, setAddrStreet] = useState("");

  const [importEmployeeList, setImportEmployeeList] = useState([]);
  const [importEmployeeRowStyle, setImportEmployeeRowStyle] = useState({});

  const inputStyle = {
    // maxHeight: 140,
    input: {
      border: "1px solid #bfbfbf",
      borderRadius: 4,
      padding: 4,
      fontSize: "2vh",
    },
    title: {
      ...Theme.text.input.title,
    },
    select: {
      border: "1px solid #bfbfbf",
      borderRadius: 4,
      padding: 4,
      fontSize: "2vh",
    },
  };

  useEffect(async () => {
    setImportEmployeeList([{ name: "", email: "" }]);
    // GoPage("ListClient");
    // GoPage("AddClient");
    // GoPage("ListEmployee");
  }, [PageInfo.show]);

  useEffect(async () => {
    console.log("verList", importEmployeeList);
  }, [importEmployeeList]);
  //BusinessAdd
  return {
    component: "container",
    style: {
      display: PageInfo.show === "business-add" ? "flex" : "none",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      width: "100%",
      maxHeight: "100vh",
      overflowX: "auto",
      overflowY: "auto",
      flex: 1,
      zIndex: 1,
      // backgroundColor: "blue",
      // paddingBottom: "2%",
    },
    children: [
      AddView({
        direction: "row",
        title: "Cadastro de empresa",
        form: {
          onSave: api.business.add,
          func: (data, info, clear, goBack) => {
            const error = data.error;
            if (error) {
              PopupShow({
                title: "Campos não preenchidos",
                description: "Por favor, revise e complete os dados",
                style: {
                  close: {
                    display: "none",
                  },
                },
                cancelText: "",
                confirmText: "Ok",
                onCancel: async () => {},
                onConfirm: async () => {},
              });
            }
            const status = data.status;
            if (status === false) {
              if (status === false)
                PopupShow({
                  title: "Este CNPJ já está cadastrado",
                  description: "Por favor, verifique os dados",
                  cancelText: "Cancelar",
                  confirmText: "Ok",
                  onCancel: async () => {
                    clear();
                    setImportEmployeeList([{ name: "", email: "" }]);
                    setBusinessName("");
                  },
                  onConfirm: async () => {
                    setImportEmployeeList([{ name: "", email: "" }]);
                  },
                });
            } else {
              setImportEmployeeList([{ name: "", email: "" }]);
            }
          },
          style: {
            main: {
              flexWrap: "wrap",
              overflow: "auto",
              maxWidth: "60%",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: 12,
              padding: "1%",
            },
            box: {
              1: {},
            },
            btn: {
              cancel: {},
              save: {},
              style: {
                width: "60%",
              },
            },
          },
          box: {
            // nome + email + cnpj + senha
            1: {
              style: {
                padding: "2%",
                flex: 1,
                minWidth: "100%",
                display: "flex",
                flexDirection: "column",
                border: "1px solid #CBD5E1",
                borderRadius: 4,
                gap: 8,
              },
              input: [
                {
                  label: "Informações Gerais",
                  style: {
                    fontSize: Theme.fontSizes.form.title,
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    color: Theme.colors.blue.life,
                    width: "100%",
                    display: "flex",
                    marginBottom: 4,
                    height: 16,
                  },
                },
                //divInput
                {
                  type: "div",
                  style: {
                    flex: 1,
                    flexDirection: "row",
                    gap: 24,
                  },
                  children: [
                    //divName + email
                    {
                      type: "div",
                      style: {
                        display: "column",
                      },
                      children: [
                        //nome
                        {
                          id: "nickname",
                          label: "Nome Fantasia",
                          type: "input",
                          style: inputStyle,
                          required: true,
                          // check: { min: 5 },
                        },
                        //email
                        {
                          id: "email",
                          label: "Email do responsável",
                          type: "input",
                          style: inputStyle,
                          required: true,
                          // check: { min: 5 },
                        },
                      ],
                    },
                    //divCpnj + senha
                    {
                      type: "div",
                      style: {
                        display: "column",
                      },
                      children: [
                        //cnpj
                        {
                          id: "code",
                          label: "CNPJ",
                          check: { type: "string" },
                          mask: "99.999.999/9999-99",
                          set: async (value) => {
                            if (value.length >= 14) {
                              const { data } = await api.utils.clientCode({
                                query: { code: value },
                              });
                              if (data?.data?.razao_social)
                                setBusinessName(data.data.razao_social);
                            } else {
                            }
                          },
                          type: "input",
                          style: inputStyle,
                          required: true,
                          // check: { min: 5 },
                        },
                        //senha
                        {
                          id: "password",
                          label: "Senha",
                          type: "input",
                          style: inputStyle,
                          required: true,
                          // check: { min: 5 },
                        },
                      ],
                    },
                  ],
                },
                //nameBusiness(Code)
                {
                  id: "name",
                  value: businessName,
                },
              ],
            },
            //localização
            2: {
              style: {
                padding: "2%",
                flex: 1,
                minWidth: "100%",
                display: "flex",
                flexDirection: "column",
                border: "1px solid #CBD5E1",
                borderRadius: 4,
                gap: 8,
              },
              input: [
                {
                  label: "Localização",
                  style: {
                    fontSize: Theme.fontSizes.form.title,
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    color: Theme.colors.blue.life,
                    width: "100%",
                    display: "flex",
                    marginBottom: 4,
                    height: 16,
                  },
                },
                //dibInputs
                {
                  type: "div",
                  style: {
                    flex: 1,
                    flexDirection: "row",
                    gap: 24,
                    alignItems: "flex-start",
                  },
                  children: [
                    //divCep + numero
                    {
                      type: "div",
                      style: {
                        display: "column",
                      },
                      children: [
                        //cep
                        {
                          id: "address_code",
                          keyid: "address.code",
                          // value: "134",
                          set: async (value) => {
                            if (value.length === 8) {
                              const { data } = await api.utils.addressCode({
                                query: { code: value },
                              });
                              if (data?.data?.cep) {
                                setAddrStreet(data.data.street);
                                setAddrState(data.data.state);
                                setAddrCity(data.data.city);
                                setAddrDistrict(data.data.neighborhood);
                              }
                            } else {
                            }
                          },
                          mask: "99999 999",
                          label: "CEP",
                          type: "input",
                          style: inputStyle,
                          required: true,
                          // check: { min: 5 },
                        },
                        //numero
                        {
                          id: "address_number",
                          keyid: "address.number",
                          label: "Número",
                          format: "number",
                          type: "input",
                          // mask: "99999",
                          style: inputStyle,
                          required: true,
                          // check: { min: 5 },
                        },
                      ],
                    },
                    //divLogadouro + complemento
                    {
                      type: "div",
                      style: {
                        display: "column",
                      },
                      children: [
                        //rua
                        {
                          id: "address_street",
                          keyid: "address.street",
                          value: addrStreet,
                          set: (value) => {
                            setAddrStreet(value);
                          },
                          label: "Logradouro",
                          type: "input",
                          style: inputStyle,
                          required: true,
                          // check: { min: 5 },
                        },
                        //complemento
                        {
                          id: "address_complement",
                          keyid: "address.complement",
                          label: "Complemento",
                          type: "input",
                          style: inputStyle,
                          required: true,
                          // check: { min: 5 },
                        },
                      ],
                    },
                    //divEstado + cidade + bairro
                    {
                      type: "div",
                      style: {
                        display: "column",
                      },
                      children: [
                        //estado
                        {
                          id: "address_state",
                          keyid: "address.state",
                          value: addrState,
                          set: (value) => {
                            setAddrState(value);
                          },
                          label: "Estado",
                          type: "input",
                          style: inputStyle,
                          required: true,
                          // check: { min: 5 },
                        },
                        //cidade
                        {
                          id: "address_city",
                          label: "Cidade",
                          keyid: "address.city",
                          value: addrCity,
                          set: (value) => {
                            setAddrCity(value);
                          },
                          type: "input",
                          style: inputStyle,
                          required: true,
                          // check: { min: 5 },
                        },
                        //bairro
                        {
                          id: "address_district",
                          label: "Bairro",
                          keyid: "address.district",
                          value: addrDistrict,
                          set: (value) => {
                            setAddrDistrict(value);
                          },
                          type: "input",
                          style: inputStyle,
                          required: true,
                          // check: { min: 5 },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            //upload
            3: {
              style: {
                padding: "2%",
                flex: 1,
                minWidth: "100%",
                display: "flex",
                flexDirection: "column",
                border: "1px solid #CBD5E1",
                borderRadius: 4,
                gap: 8,
              },
              input: [
                {
                  label: "Lista de funcionários",
                  style: {
                    fontSize: Theme.fontSizes.form.title,
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    color: Theme.colors.blue.life,
                    width: "100%",
                    display: "flex",
                    marginBottom: 4,
                    height: 16,
                  },
                },
                //divInput
                {
                  type: "div",
                  style: {
                    flex: 1,
                    gap: 24,
                    flexDirection: "row",
                  },
                  children: [
                    // Campo de upload de arquivo
                    {
                      id: "file",
                      title: "",
                      label: "Clique para fazer o upload",
                      type: "file",
                      value: importEmployeeList,
                      icon: "",
                      style: {
                        title: {
                          fontSize: Theme.fontSizes.form.title,
                          fontFamily: "Poppins",
                          color: Theme.colors.secondary,
                          width: "100%",
                          display: "flex",
                        },
                        label: {
                          fontSize: Theme.fontSizes.form.title,
                          fontFamily: "Poppins",
                          color: "blue",
                        },
                        box: {
                          backgroundColor: Theme.colors.blue.light,
                          padding: 8,
                          borderRadius: 4,
                        },
                      },
                      func: async (data) => {
                        const workbook = XLSX.read(data, {
                          type: "array",
                        });
                        const worksheet =
                          workbook.Sheets[Object.keys(workbook.Sheets)[0]];
                        const json = XLSX.utils.sheet_to_json(worksheet);
                        let employeeList = [];
                        json.map((employee) => {
                          employeeList.push(employee);
                        });
                        setImportEmployeeList(employeeList);
                        setImportEmployeeRowStyle({});
                      },
                    },
                    //Campo baixar modelo excel
                    {
                      label: "Baixe o modelo de planilha.",
                      type: "button",
                      style: {
                        button: {
                          justifyContent: "center",
                          width: "18vw",
                        },
                        label: {
                          fontSize: "0.88vw",
                          color: Theme.colors.primary,
                          fontFamily: "Poppins",
                          textDecoration: "underline",
                          cursor: "pointer",
                          padding: 8,
                          borderRadius: 4,
                          textAlign: "center",
                          fontWeight: "400",
                          backgroundColor: "rgba(100,100,100,0.16)",
                          width: "100%",
                        },
                      },
                      onClick: async () => {
                        api.download.sheetModel("modelo_cadastro.xlsx");
                      },
                    },
                  ],
                },
              ],
            },
          },
        },
        // formData: importEmployeeList,
        showPopup: false,
      }),
      //ListView
      {
        component: "container",
        style: {
          display: "flex",
          width: "60%",
          padding: "2%",
          marginLeft: "2%",
          marginBottom: "2%",
          borderRadius: 4,
        },
        children: [
          ListView({
            id: "ListImportEmployee",
            title: "Colaboradores",
            topBar: false,
            style: {
              cel: (props) => {
                switch (props.col) {
                  default:
                    return {
                      fontSize: Theme.fontSizes.row,
                      fontWeight: "400",
                    };
                }
              },
              row: {
                width: "100%",
                fontWeight: "normal",
                display: "flex",
                fontSize: Theme.fontSizes.row,
                fontFamily: Theme.fontFamilies.primary,
                minHeight: 42,
                maxHeight: 42,
                alignItems: "center",
                justifyContent: "center",
                padding: 8,
              },
              col: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: Theme.fontSizes.col,
                borderRight: "none",
                fontWeight: "bold",
                padding: 8,
                minHeight: 32,
                maxHeight: 32,
              },
            },
            col: {
              // id: { title: "ID", style: { flex: 0.1 } },
              nome: {
                title: "Nome",
                style: {
                  flex: 2,
                },
              },
              email: {
                // key: ["contact", "email"],
                title: "E-mail",
              },
            },
            item: importEmployeeList,
          }),
        ],
      },
    ],
  };
};
