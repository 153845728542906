import Theme from "theme";
import React, { useState, useEffect } from "react";
import InputMask from "react-input-mask";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const testFields = (field) => {
  field.type = field.type.toLowerCase();
  if (!field?.value || field?.value?.length === 0 || field?.value === "")
    return { error: "Campo invalido" };
  else if (field.type === "number") {
    if (isNaN(field.value)) {
      return true;
    } else return { error: "Numero invalido" };
  } else if (field.type === "email") {
    if (field.value.includes("@")) {
      return true;
    } else return { error: "Email invalido" };
  } else if (field.type === "string") {
    if (field.value !== "" && field.value !== " ") {
      return true;
    } else return { error: "Campo invalido" };
  } else if (field.type === "password") {
    if (field.value !== "") {
      return true;
    } else return { error: "Campo invalido" };
  } else return { error: "Campo invalido" };
};

export default (param) => {
  const [inpt, setInpt] = useState({
    value: "",
    focus: false,
    error: false,
    ...param,
  });

  const [options, setOptions] = useState([]);

  const items = {};

  useEffect(() => {
    if (param.item) {
      if (Array.isArray(param.item)) {
        // param.item.unshift({
        //   _id: "none",
        //   name: "Selecione",
        // });
        setOptions(param.item);
      } else
        param.item().then(({ data }) => {
          if (data?.data) {
            // data.data.unshift({
            //   _id: "none",
            //   name: "Selecione",
            // });
            setOptions(data.data);
          }
        });
    }
  }, []);

  useEffect(() => {
    if (param.item) {
      if (Array.isArray(param.item)) {
        param.item.unshift({
          _id: "none",
          name: "Selecione",
        });
        setOptions(param.item);
      } else
        param.item().then(({ data }) => {
          if (data?.data) {
            data.data.unshift({
              _id: "none",
              name: "Selecione",
            });
            setOptions(data.data);
          }
        });
    }
  }, [param.update]);

  return {
    component: "container",
    style: {
      flex: 1,
      width: "100%",
      minHeight: "10%",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      borderRadius: 4,
      ...param.style?.box,
    },
    // onClick: () => { setInpt({...inpt, focus: true}) },
    children: [
      {
        component: "text",
        style: {
          display: param.title ? "flex" : "none",
          flex: 1,
          // textWrap: "nowrap",
          fontSize: "1vh",
          marginTop: 4,
          marginBottom: 2,
          fontWeight: inpt.focus ? "bold" : "normal",
          color: inpt.focus ? Theme.colors.primary : Theme.colors.black.full,
          ...param.style?.title,
        },
        text: param.title || "",
      },

      // criar um input mask geral com dados de entrada
      {
        render: (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              width: "100%",
              height: "100%",
              border: "none",
              ...param.style?.label,
            }}
          >
            {param.icon && (
              <img
                style={{
                  width: "30px",
                  height: "30px",
                  marginRight: "1vw",
                  filter: inpt.focus ? "invert(1)" : "invert(0)",
                  ...param.style?.icon,
                }}
              >
                {" "}
              </img>
            )}
            <select
              style={{
                // control: (styles) => ({ ...styles, backgroundColor: "white" }),
                // option: (
                //   styles,
                //   { data, isDisabled, isFocused, isSelected }
                // ) => {
                //   const color = chroma(data.color);
                //   return {
                //     ...styles,
                //     backgroundColor: isDisabled ? "red" : blue,
                //     color: "#FFF",
                //     cursor: isDisabled ? "not-allowed" : "default",
                //   };
                // },

                height: 34,
                flex: 1,
                width: "100%",
                backgroundColor: Theme.colors.gray.light,
                // ...Theme.borders.small,
                border: "1px solid #bfbfbf",
                borderRadius: 4,
                fontFamily: "Poppins",
                // fontSize: Theme.fontSizes.normal,
                color: Theme.colors.gray.dark,
                ...param.style?.select,
              }}
              name={param.title}
              onChange={async (e) => {
                setInpt({ ...inpt, value: e.target.value });
                if (param.onChange) {
                  try {
                    param.onChange(items[e.target.value]);
                  } catch (err) {
                    console.log(err);
                  }
                }
              }}
              value={param.value}
              // onChange={(e) => handleOnChange(e, index)}
              onClick={async (e) => {
                if (param.onClick) {
                  try {
                    param.onClick(e);
                  } catch (err) {
                    console.log(err);
                  }
                }
              }}
            >
              {options.map((op) => {
                items[op._id] = op;
                return <option value={op._id}>{op.name}</option>;
              })}
            </select>
          </div>
        ),
      },
    ],
  };
};
