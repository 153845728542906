import { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import { Render } from "@8_dev/sjson-web";

//api
import api from "service";

//context
import { ShowPage } from "context/showPage";
import { ShowPopup } from "context/popup";

//components/web
import ListView from "components/web/view/list";
import Button from "components/web/button";

//images

//pages

import Theme from "theme";

export default () => {
  const { PageInfo, GoPage, setUpdate } = ShowPage();
  const { PopupShow, PopupClose } = ShowPopup();
  const [popUp, setPopUp] = useState(false);
  const [selectedHours, setSelectedHours] = useState([]);

  const [item, setItem] = useState({});

  const [selectState, setSelectState] = useState(-1);

  return {
    component: "container",
    style: {
      flex: 1,
      display: PageInfo.show === "finance-list" ? "flex" : "none",
    },
    children: [
      {
        component: "container",
        style: {
          width: "100%",
          flex: 1,
        },
        children: [
          ListView({
            id: "finance-list",
            title: "Financeiro -  Pagamento de parceiros",
            //filtros
            filter: [
              //novos pedidos
              {
                id: "WaitingOrder",
                type: "button",
                label: "Pedidos em aberto",
                query: "waiting",
                // count: ,
                style: {
                  box: {},
                },
              },
              //pedidos finalizados
              {
                id: "OrderFinish",
                type: "button",
                label: "Pedidos pagos",
                query: "finish",
                style: {
                  box: {},
                },
              },
            ],
            style: {
              cel: (props) => {
                switch (props.col) {
                  default:
                    return {
                      fontWeight: "normal",
                      // flex: 1.2,
                    };
                }
              },
              row: {
                width: "100%",
                fontWeight: "normal",
                display: "flex",
                fontSize: Theme.fontSizes.row,
                fontFamily: Theme.fontFamilies.primary,
                minHeight: 64,
                maxHeight: 64,
                alignItems: "center",
                justifyContent: "center",
                padding: 2,
              },
              col: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: Theme.fontSizes.col,
                borderRight: "none",
                fontWeight: "bold",
                padding: 8,
                minHeight: 32,
                maxHeight: 32,
              },
            },
            col: {
              id: {
                title: "ID",
                condition: (id) => {
                  return "# " + id.id;
                },
                style: {},
              },
              provider: {
                key: ["provider", "nickname"],
                title: "Parceiro",
                style: {
                  flex: 1.5,
                },
              },
              bank_details: {
                title: "Dados bancários",
                item: [
                  {
                    value: ({ row }) => {
                      return {
                        component: "container",
                        style: {
                          display: "flex",
                          width: "100%",
                          height: "100%",
                          alignItems: "flex-start",
                          justifyContent: "center",
                          flexDirection: "column",
                          padding: 2,
                          gap: 4,
                        },
                        children: [
                          //banco
                          {
                            component: "container",
                            style: {
                              display: "flex",
                              width: "auto",
                              height: "auto",
                              flexDirection: "row",
                              alignItems: "center",
                              gap: 2,
                            },
                            children: [
                              {
                                component: "text",
                                text: "Banco:",
                              },
                              {
                                component: "text",
                                text:
                                  row?.provider?.bank_account?.bank?.code ||
                                  "-",
                              },
                            ],
                          },
                          //Agencia
                          {
                            component: "container",
                            style: {
                              display: "flex",
                              width: "auto",
                              height: "auto",
                              flexDirection: "row",
                              gap: 2,
                            },
                            children: [
                              {
                                component: "text",
                                text: "Agência:",
                              },
                              {
                                component: "text",
                                text:
                                  row?.provider?.bank_account?.agency_code ||
                                  "-",
                              },
                            ],
                          },
                          //conta
                          {
                            component: "container",
                            style: {
                              display: "flex",
                              width: "auto",
                              height: "auto",
                              flexDirection: "row",
                              gap: 2,
                            },
                            children: [
                              {
                                component: "text",
                                text: "Conta:",
                              },
                              {
                                component: "text",
                                text:
                                  row?.provider?.bank_account?.account_code ||
                                  "-",
                              },
                            ],
                          },
                        ],
                      };
                    },
                  },
                ],
              },
              pix_key: {
                key: ["provider", "bank_account", "pix_key"],
                title: "Chave pix",
                style: {},
              },
              business: {
                key: ["business", "nickname"],
                title: "Fonte",
                style: {},
              },
              service_value: {
                title: "Valor total",
                condition: (param) => {
                  return "R$ " + param?.service_value;
                },
                style: {},
              },
              value_transfer: {
                title: "Valor de repasse",
                condition: (param) => {
                  return "R$ " + "1";
                },
                style: {},
              },
              service_date: {
                // key: [],
                title: "Data",
                style: {},
              },
              date_limit: {
                title: "Data limite",
                style: {},
              },
              status: {
                title: "Status",
                style: {},
                item: [
                  {
                    value: ({ row }) => {
                      return {
                        component: "container",
                        style: {
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        },
                        children: [
                          {
                            render: (
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                }}
                              >
                                <select
                                  value={selectState}
                                  onChange={async (item) => {
                                    console.log("verSelect", row, item);
                                    if (item.target.value === "1") {
                                      PopupShow({
                                        title:
                                          "Deseja confirmar " + row.id + " ?",
                                        cancelText: "Não",
                                        confirmText: "Sim",
                                        onConfirm: async () => {
                                          setSelectState(-1);
                                          setUpdate(
                                            "finance-list" + new Date()
                                          );
                                          GoPage("finance-list");
                                        },
                                        onCancel: async () => {
                                          setUpdate(
                                            "finance-list" + new Date()
                                          );
                                          setSelectState(-1);
                                        },
                                        item: [],
                                      });
                                    }
                                  }}
                                  style={{
                                    width: "auto",
                                    display:
                                      row.status.code >= 1 ? "flex" : "none",
                                    height: 26,
                                    borderStyle: "solid",
                                    borderWidth: 1,
                                    backgroundColor:
                                      row.status.code === 1
                                        ? "rgba(0, 128, 0, 0.3)"
                                        : "rgba(243, 239, 239, 1)",
                                    borderRadius: 5,
                                  }}
                                  disabled={row.status.code === 1}
                                >
                                  {row.status?.code > 1 ? (
                                    <>
                                      <option value={-1} disabled selected>
                                        Em aberto
                                      </option>
                                      <option value={1}>Pago</option>
                                    </>
                                  ) : row.status?.code === 1 ? (
                                    <option value={1}>Pago</option>
                                  ) : (
                                    <option></option>
                                  )}
                                </select>
                              </div>
                            ),
                          },
                        ],
                      };
                    },
                  },
                ],
              },
            },
            item: api.financial.list,
          }),
        ],
      },
    ],
  };
};