import { useEffect, useState } from "react";

import api from "service";
import { ShowPage } from "context/showPage";
import { ShowPopup } from "context/popup";
import AddView from "components/web/view/add";
import PopUp from "components/web/popup";

import Theme from "theme";

// importar pagina padrao e criar variveis para add e listar...
// import AddClient from "pages/admin/client/add";
// import ListClient from "pages/admin/client/list";

export default (props) => {
  const { PageInfo, GoPage } = ShowPage();
  const { PopupShow, PopupClose } = ShowPopup();

  //Varieavel que vai receber o switch
  const [typeProvider, setTypeProvider] = useState(false);

  //Nome cnpj Provider
  const [employeeNameProvider, setEmployeeNameProvider] = useState("");

  //Busa cep provider
  const [addrStateProvider, setAddrStateProvider] = useState("");
  const [addrCityProvider, setAddrCityProvider] = useState("");
  const [addrDistrictProvider, setAddrDistrictProvider] = useState("");
  const [addrStreetProvider, setAddrStreetProvider] = useState("");

  useEffect(() => {}, []);

  const [typePix, setTypePix] = useState("");

  const inputStyle = {
    // maxHeight: 140,
    input: {
      border: "1px solid #bfbfbf",
      borderRadius: 4,
      padding: 4,
      fontSize: "2vh",
      maxWidth: "100%",
    },
    title: Theme.text.input.title,
    select: {
      border: "1px solid #bfbfbf",
      borderRadius: 4,
      padding: 4,
      fontSize: "2vh",
      maxHeight: 32,
    },
  };

  useEffect(async () => {
    // GoPage("ListClient");
    // GoPage("AddClient");
    // GoPage("ListEmployee");
  }, []);

  //Adicionar parceiros
  return {
    component: "container",
    style: {
      display: PageInfo.show === "provider-add" ? "flex" : "none",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      width: "100%",
      maxHeight: "100vh",
      overflowX: "auto",
      overflowY: "auto",
      flex: 1,
      zIndex: 1,
    },
    children: [
      AddView({
        direction: "row",
        title: "Cadastro de parceiros",
        form: {
          onSave: api.provider.add,
          func: (data, info, clear, goBack) => {
            console.log("ver error", data.error);
            const error = data.error;
            if (error) {
              PopupShow({
                title: "Campos não preenchidos",
                description: "Por favor, revise e complete os dados",
                style: {
                  close: {
                    display: "none",
                  },
                },
                cancelText: "",
                confirmText: "Ok",
                onCancel: async () => {},
                onConfirm: async () => {},
              });
            }
            const status = data.status;
            if (status === false) {
              if (status === false)
                PopupShow({
                  title: "Este CNPJ já está cadastrado ",
                  description: "Você deseja continuar?",
                  cancelText: "Não",
                  confirmText: "Sim",
                  style: {
                    close: {
                      display: "none",
                    },
                  },
                  onCancel: async () => {
                    clear();
                    setEmployeeNameProvider("");
                  },
                  onConfirm: async () => {
                    const result = await api.provider.add({
                      force: true,
                      ...info,
                    });
                    if (result?.data?._id) {
                      clear();
                      setEmployeeNameProvider("");
                      PopupShow({
                        title:
                          (result.data.nickname || result.data.name) +
                          ", foi adicionado!",
                        description: "Deseja continuar",
                        cancelText: "Não",
                        confirmText: "Sim",
                        onCancel: async () => {
                          clear();
                          goBack();
                        },
                      });
                    }
                  },
                });
            } else {
            }
          },
          style: {
            main: {
              flexWrap: "wrap",
              overflow: "auto",
              maxWidth: "60%",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: 12,
              padding: "1%",
            },
            box: {
              1: {},
            },
            btn: {
              cancel: {},
              save: {},
              style: {
                maxWidth: "60%",
              },
            },
          },
          box: {
            //Informações pessoais
            1: {
              style: {
                padding: "2%",
                flex: 1,
                minWidth: "100%",
                display: "flex",
                flexDirection: "column",
                border: "1px solid #CBD5E1",
                borderRadius: 4,
                gap: 8,
              },
              input: [
                {
                  label: "Informações Gerais",
                  style: {
                    fontSize: Theme.fontSizes.form.title,
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    color: Theme.colors.blue.life,
                    width: "100%",
                    display: "flex",
                    marginBottom: 4,
                    height: 16,
                  },
                },
                //divInput
                {
                  type: "div",
                  style: {
                    flex: 1,
                    flexDirection: "row",
                    alignItems: "flex-start",
                    gap: 24,
                    height: "100%",
                  },
                  children: [
                    //divName + email + tipo de cadastro + prazo de repasse
                    {
                      type: "div",
                      style: {
                        display: "column",
                        gap: 8,
                      },
                      children: [
                        //Nome
                        {
                          id: "nickname",
                          label: "Nome Fantasia",
                          type: "input",
                          style: inputStyle,
                          required: true,
                          // check: { min: 5 },
                        },
                        //email
                        {
                          id: "email",
                          label: "Email do responsável",
                          type: "input",
                          style: inputStyle,
                          required: true,
                          // check: { min: 5 },
                        },
                        //Tipo de cadastro
                        {
                          id: "register_type",
                          // keyid: "register_type",
                          label: "Tipo de cadastro",
                          type: "select",
                          option: [
                            {
                              _id: 1,
                              name: "Fidelizado",
                            },
                            {
                              _id: 2,
                              name: "Cadastrado",
                            },
                          ],
                          style: {
                            ...inputStyle,
                          },
                          required: true,
                        },
                        //Prazo de repasse
                        {
                          id: "transfer_deadline",
                          // keyid: "register_type",
                          // value: 30,
                          label: "Prazo de repasse",
                          type: "select",
                          option: [
                            {
                              _id: 1,
                              name: "D + 1",
                            },
                            {
                              _id: 30,
                              name: "D + 30",
                            },
                          ],
                          style: {
                            ...inputStyle,
                          },
                          required: true,
                        },
                        //ID
                        {
                          id: "_id",
                          label: "",
                          type: "input",
                          style: {
                            display: "none",
                          },
                          required: true,
                        },
                        //Type
                        {
                          id: "type",
                          label: "",
                          value: "provider",
                          type: "input",
                          style: {
                            display: "none",
                          },
                          required: true,
                        },
                      ],
                    },
                    //divCpnj + senha + whatsApp
                    {
                      type: "div",
                      style: {
                        display: "flex",
                        display: "column",
                      },
                      children: [
                        //nameCNPJ
                        {
                          id: "name",
                          value: employeeNameProvider,
                        },
                        //cnpj
                        {
                          type: "div",
                          style: {
                            display: "flex",
                            alignItems: "flex-start",
                          },
                          children: [
                            //input
                            {
                              id: "code",
                              keyid: "code",
                              label: typeProvider ? "CPF" : "CNPJ",
                              type: "input",
                              style: inputStyle,
                              check: { type: "string" },
                              mask: typeProvider
                                ? "999.999.999-99"
                                : "99.999.999/9999-99",
                              set: async (value) => {
                                if (typeProvider === false) {
                                  if (value.length >= 14) {
                                    const { data } = await api.utils.clientCode(
                                      {
                                        query: { code: value },
                                      }
                                    );
                                    if (data?.data?.razao_social)
                                      setEmployeeNameProvider(
                                        data.data.razao_social
                                      );
                                    else setEmployeeNameProvider("");
                                  } else setEmployeeNameProvider("");
                                } else setEmployeeNameProvider("");
                              },
                              required: true,
                              // check: { min: 5 },
                            },
                            // Switch
                            {
                              type: "switch",
                              label: "Pessoa física",
                              style: {
                                position: "absolute",
                                marginTop: -32,
                                marginLeft: "28%",
                                display: "flex",
                                fontFamily: "Poppins",
                                fontSize: 12,
                              },
                              onChange: async (v) => {
                                // console.log("eeee", v);
                                setTypeProvider(v);
                              },
                              checked: true,
                              value: typeProvider,
                            },
                          ],
                        },
                        //Senha
                        {
                          id: "password",
                          label: "Senha",
                          type: "input",
                          style: inputStyle,
                          required: true,
                          // check: { min: 5 },
                        },
                        //WhatsApp
                        {
                          id: "whatsapp",
                          label: "WhatsApp(opcional)",
                          type: "input",
                          mask: "(99)99999-9999",
                          style: inputStyle,
                          required: true,
                          // check: { min: 5 },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            2: {
              style: {
                padding: "2%",
                flex: 1,
                minWidth: "100%",
                display: "flex",
                flexDirection: "column",
                border: "1px solid #CBD5E1",
                borderRadius: 4,
                gap: 8,
              },
              input: [
                {
                  label: "Localização",
                  style: {
                    fontSize: Theme.fontSizes.form.title,
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    color: Theme.colors.blue.life,
                    width: "100%",
                    display: "flex",
                    marginBottom: 4,
                    height: 16,
                  },
                },
                //divInputs
                {
                  type: "div",
                  style: {
                    flex: 1,
                    flexDirection: "row",
                    gap: 24,
                    alignItems: "flex-start",
                  },
                  children: [
                    //divCep + numero
                    {
                      type: "div",
                      style: {
                        display: "column",
                      },
                      children: [
                        //cep
                        {
                          id: "address_code",
                          keyid: "address.code",
                          mask: "99999 999",
                          set: async (value) => {
                            if (value.length === 8) {
                              const { data } = await api.utils.addressCode({
                                query: { code: value },
                              });
                              if (data?.data?.cep) {
                                setAddrStreetProvider(data.data.street);
                                setAddrStateProvider(data.data.state);
                                setAddrCityProvider(data.data.city);
                                setAddrDistrictProvider(data.data.neighborhood);
                              }
                            } else {
                            }
                          },
                          label: "CEP",
                          type: "input",
                          style: inputStyle,
                          required: true,
                          // check: { min: 5 },
                        },
                        //numero
                        {
                          id: "address_number",
                          keyid: "address.number",
                          label: "Número",
                          type: "input",
                          format: "number",
                          style: inputStyle,
                          // mask: "999999",
                          required: true,
                        },
                      ],
                    },
                    //divLogadouro + complemento
                    {
                      type: "div",
                      style: {
                        display: "column",
                      },
                      children: [
                        //rua
                        {
                          id: "address_street",
                          keyid: "address.street",
                          value: addrStreetProvider,
                          set: (value) => {
                            setAddrStreetProvider(value);
                          },
                          label: "Logradouro",
                          type: "input",
                          style: inputStyle,
                          required: true,
                          // check: { min: 5 },
                        },
                        //complemento
                        {
                          id: "address_complement",
                          keyid: "address.complement",
                          label: "Complemento",
                          type: "input",
                          style: inputStyle,
                          required: false,
                          // check: { min: 5 },
                        },
                      ],
                    },
                    //divEstado + cidade + bairro
                    {
                      type: "div",
                      style: {
                        display: "column",
                      },
                      children: [
                        //estado
                        {
                          id: "address_state",
                          keyid: "address.state",
                          value: addrStateProvider,
                          set: (value) => {
                            setAddrStateProvider(value);
                          },
                          label: "Estado",
                          type: "input",
                          style: inputStyle,
                          required: true,
                          // check: { min: 5 },
                        },
                        //cidade
                        {
                          id: "address_city",
                          label: "Cidade",
                          keyid: "address.city",
                          value: addrCityProvider,
                          set: (value) => {
                            setAddrCityProvider(value);
                          },
                          type: "input",
                          style: inputStyle,
                          required: true,
                          // check: { min: 5 },
                        },
                        //bairro
                        {
                          id: "address_district",
                          label: "Bairro",
                          keyid: "address.district",
                          value: addrDistrictProvider,
                          set: (value) => {
                            setAddrDistrictProvider(value);
                          },
                          type: "input",
                          style: inputStyle,
                          required: true,
                          // check: { min: 5 },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            // Banco + tipo de chave + chave pix / Agência + Digito + Conta + Digito
            3: {
              style: {
                padding: "2%",
                flex: 1,
                minWidth: "100%",
                display: "flex",
                flexDirection: "column",
                border: "1px solid #CBD5E1",
                borderRadius: 4,
                gap: 8,
              },
              input: [
                {
                  label: "Dados bancários",
                  style: {
                    fontSize: Theme.fontSizes.form.title,
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    color: Theme.colors.blue.life,
                    width: "100%",
                    display: "flex",
                    marginBottom: 4,
                    height: 16,
                  },
                },
                // (Div) Banco + tipo de chave + chave pix
                {
                  type: "div",
                  style: {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    width: "100%",
                  },
                  children: [
                    //banco
                    {
                      id: "bank",
                      keyid: "bank_account.bank",
                      label: "Selecione o banco",
                      type: "select",
                      option: api.utils.bank,
                      style: {
                        ...inputStyle,
                        marginTop: "auto",
                      },
                      required: false,
                    },
                    //tipo de chave
                    {
                      id: "pix_type",
                      keyid: "bank_account.pix_type",
                      label: "Tipo de chave",
                      type: "select",
                      option: api.utils.pix_type,
                      func: (item) => {
                        setTypePix(item.name);
                      },
                      style: {
                        ...inputStyle,
                        marginTop: "auto",
                      },
                      required: true,
                    },
                    //Chave pix
                    {
                      id: "pix_key",
                      keyid: "bank_account.pix_key",
                      label: "Chave pix",
                      mask:
                        typePix === "cpf"
                          ? "999.999.999-99"
                          : typePix === "cnpj"
                          ? "99.999.999/9999-99"
                          : typePix === "telefone"
                          ? "(99)99999-9999"
                          : "",
                      type: "input",
                      style: inputStyle,
                      required: true,
                      // check: { min: 5 },
                    },
                  ],
                },
                //(Div) Agência + Digito_agencia + Conta + Digito_account
                {
                  type: "div",
                  style: {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    width: "100%",
                  },
                  children: [
                    //Conta + Digito
                    {
                      type: "div",
                      style: {
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: "row",
                        maxWidth: "35%",
                      },
                      children: [
                        //agencia
                        {
                          id: "agency",
                          keyid: "bank_account.agency",
                          label: "Agência",
                          type: "input",
                          style: {
                            ...inputStyle,
                            maxWidth: "60%",
                          },
                          required: true,
                          // check: { min: 5 },
                        },
                        //Digito_agencia
                        {
                          id: "agency_code",
                          keyid: "bank_account.agency_code",
                          label: "Digito",
                          type: "input",
                          style: {
                            ...inputStyle,
                            maxWidth: "30%",
                          },
                          required: true,
                          // check: { min: 5 },
                        },
                      ],
                    },
                    //Conta + Digito
                    {
                      type: "div",
                      style: {
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: "row",
                        maxWidth: "35%",
                        marginLeft: 12,
                      },
                      children: [
                        //Conta
                        {
                          id: "account",
                          keyid: "bank_account.account",
                          label: "Conta",
                          type: "input",
                          style: {
                            ...inputStyle,
                            maxWidth: "60%",
                          },
                          required: true,
                          // check: { min: 5 },
                        },
                        //Digito_account
                        {
                          id: "account_code",
                          keyid: "bank_account.account_code",
                          label: "Digito",
                          type: "input",
                          style: {
                            ...inputStyle,
                            maxWidth: "30%",
                          },
                          required: true,
                          // check: { min: 5 },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            //upload
            4: {
              style: {
                padding: "2%",
                flex: 1,
                minWidth: "100%",
                display: "flex",
                flexDirection: "column",
                border: "1px solid #CBD5E1",
                borderRadius: 4,
                gap: 8,
              },
              input: [
                {
                  label: "Logotipo do parceiro",
                  style: {
                    fontSize: Theme.fontSizes.form.title,
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    color: Theme.colors.blue.life,
                    width: "100%",
                    display: "flex",
                    marginBottom: 4,
                    height: 16,
                  },
                },
                //divInput
                {
                  type: "div",
                  style: {
                    width: "80%",
                    justifyContent: "center",
                    flexDirection: "row",
                  },
                  children: [
                    {
                      id: "company_icon",
                      title: "",
                      label: "Clique para fazer o upload",
                      type: "file",
                      icon: "",
                      required: true,
                      style: {
                        title: {},
                        label: {
                          fontSize: Theme.fontSizes.form.title,
                          fontFamily: "Poppins",
                          color: "blue",
                          textAlign: "center",
                        },
                        box: {
                          maxWidth: "50%",
                          backgroundColor: Theme.colors.blue.light,
                          padding: 8,
                          borderRadius: 4,
                          alignItems: "center",
                          flexDirection: "center",
                          // border: "1px solid black",
                        },
                      },
                      // check: { min: 5 },
                    },
                  ],
                },
              ],
            },
          },
        },
        showPopup: false,
      }),
    ],
  };
};
