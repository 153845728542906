import { Render } from "@8_dev/sjson-web";
import { useEffect, useState } from "react";
import { Fade } from "react-reveal";
import { ShowPopup } from "context/popup";

//context
import { ShowPage } from "context/showPage";

//theme
import Theme from "theme";

//api
import api from "service";

//components
import TextArea from "components/web/textArea";
import ContentTopBar from "components/web/ContentTopBar";
import Input from "components/web/input";
import Select from "components/web/select";
import Button from "components/web/button";

//pages
import Page from "../../init";

//images
import Arrow from "assets/images/menu/arrowBack.svg";

export default (props) => {
  const { GoPage, PageInfo, GoBack, setUpdate } = ShowPage();
  const { PopupShow, PopupClose } = ShowPopup();

  const [cityList, setCityList] = useState([]);

  const [remainingChars, setRemainingChars] = useState(160);

  const [messageValue, setMessageValue] = useState("");
  const [username, setUsername] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedAnimalCategory, setSelectedAnimalCategory] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedSituation, setSelectedSituation] = useState("");

  const handleTextChange = (e) => {
    const inputValue = e.target.value;

    setMessageValue(e.target.value);
    setRemainingChars(160 - inputValue.length);
  };

  const inputStyle = {
    // maxHeight: 140,
    input: {
      border: "1px solid #bfbfbf",
      minHeight: 42,
      borderRadius: 4,
      padding: 8,
      fontSize: 14,
      maxWidth: "100%",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    title: {
      ...Theme.text.input.title,
      marginBottom: 8,
    },
    select: {
      border: "1px solid #bfbfbf",
      borderRadius: 4,
      padding: 4,
      fontSize: 14,
    },
  };

  const inputName = Input({
    title: "Nome de identificação",
    value: username,
    setValue: setUsername,
    placeholder: "Ex: Black friday 2024",
    style: {
      ...inputStyle,
    },
  });

  const selectTypePet = Select({
    title: "Por tipo de pet",
    item: api.utils.animal_category,
    onChange: (e) => {
      setSelectedAnimalCategory(e._id);
    },
    style: {
      ...inputStyle,
    },
  });

  const selectState = Select({
    title: "Por estado",
    item: api.utils.state_name,
    onChange: async (e) => {
      // console.log("ver estado", e);
      setSelectedState(e._id);
      const filterCity = await api.utils.city_name({
        query: {
          id: e._id,
        },
      });
      setCityList(filterCity.data.data);
      console.log("state", e);
    },
    style: {
      ...inputStyle,
    },
  });

  const selectCity = Select({
    title: "Por cidade",
    item: cityList,
    update: cityList,
    onChange: (e) => {
      setSelectedCity(e._id);
    },
    style: {
      ...inputStyle,
    },
  });

  const selectSituation = Select({
    title: "Por situação",
    item: api.utils.marketing_situation,
    onChange: (e) => {
      setSelectedSituation(e._id);
    },
    style: {
      ...inputStyle,
    },
  });

  useEffect(async () => {
    if (PageInfo.show === "pushUp") {
      setCityList([]);
      setMessageValue("");
      setUsername("");
      setSelectedState("");
      setSelectedAnimalCategory("");
      setSelectedCity("");
      setSelectedSituation("");
      setRemainingChars(160);
    }
  }, [PageInfo]);

  return {
    render: (
      <div
        style={{
          flex: 1,
          display: PageInfo.show === "pushUp" ? "flex" : "none",
          flexDirection: "column",
        }}
      >
        <Fade>
          {Render({
            component: "container",
            style: {
              display: "flex",
              width: "100%",
              height: "100%",
              padding: "1%",
              flexDirection: "column",
            },
            children: [
              //topBar
              ContentTopBar({
                title: "Criação de novo pushUp",
                src: Arrow,
              }),
              //content
              {
                component: "container",
                style: {
                  flexWrap: "wrap",
                  overflow: "auto",
                  maxWidth: "50%",
                  height: "auto",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "1%",
                  margin: "1%",
                  padding: "2%",
                  border: "1px solid #CBD5E1",
                  borderRadius: 4,
                },
                children: [
                  //infoGeral
                  {
                    component: "text",
                    text: "Informações gerais",
                    style: {
                      fontSize: Theme.fontSizes.form.title,
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                      color: Theme.colors.blue.life,
                      width: "100%",
                      display: "flex",
                      marginBottom: 16,
                      height: 16,
                    },
                  },
                  inputName,
                  //textArea
                  {
                    render: (
                      <div>
                        <h1
                          style={{
                            ...Theme.text.input.title,
                            marginBottom: 8,
                            marginTop: 18,
                          }}
                        >
                          Mensagem
                        </h1>
                        <TextArea
                          key="textarea"
                          value={messageValue}
                          onChange={handleTextChange}
                          maxLength={160}
                          style={{
                            width: "100%",
                            backgroundColor: "transparent",
                          }}
                          placeholder="Digite aqui até 160 caracteres"
                        />
                        <div
                          style={{
                            marginTop: 8,
                            color: remainingChars > 10 ? "gray" : "red",
                            fontFamily: "Poppins",
                            fontSize: 12,
                          }}
                        >
                          {remainingChars} caracteres restantes
                        </div>
                      </div>
                    ),
                  },
                  //filtros
                  {
                    component: "text",
                    text: "Filtros",
                    style: {
                      fontSize: Theme.fontSizes.form.title,
                      fontFamily: "Poppins",
                      fontWeight: "bold",
                      color: Theme.colors.blue.life,
                      width: "100%",
                      display: "flex",
                      marginTop: "12%",
                      height: 16,
                    },
                  },
                  //selects
                  {
                    component: "container",
                    style: {
                      display: "flex",
                      flexDirection: "row",
                      marginTop: 12,
                      gap: 18,
                    },
                    children: [
                      selectTypePet,
                      selectState,
                      selectCity,
                      selectSituation,
                    ],
                  },
                  //botões
                  {
                    component: "container",
                    style: {
                      display: "flex",
                      width: "100%",
                      alignItems: "flex-end",
                      justifyContent: "flex-end",
                      marginTop: "8%",
                    },
                    children: [
                      //btnCancelForm
                      Button({
                        style: {
                          button: {
                            padding: 8,
                            paddingLeft: 16,
                            paddingRight: 16,
                            alignItems: "center",
                            borderRadius: 4,
                            border: "none",
                            backgroundColor: "transparent",
                          },
                          label: {
                            fontSize: 14,
                            color: Theme.colors.red.light,
                            fontFamily: "Poppins",
                          },
                        },
                        label: "cancelar",
                        onPress: () => {
                          PopupShow({
                            title: "Deseja cancelar ?",
                            description: "",
                            cancelText: "não",
                            style: {
                              close: {
                                display: "none",
                              },
                            },
                            confirmText: "sim",
                            onCancel: async () => {
                              PopupClose();
                            },
                            onConfirm: async () => {
                              GoBack();
                            },
                          });
                        },
                      }),
                      //btnSentForm
                      Button({
                        style: {
                          button: {
                            padding: 8,
                            paddingLeft: 16,
                            paddingRight: 16,
                            alignItems: "center",
                            borderRadius: 4,
                            border: "none",
                            backgroundColor: Theme.colors.blue.life,
                          },
                          label: {
                            fontSize: 14,
                            color: "white",
                            fontFamily: "Poppins",
                          },
                        },
                        label: "Enviar mensagem",
                        onPress: async () => {
                          // console.log("verSent", username, textValue);
                          if (
                            username &&
                            messageValue &&
                            selectedAnimalCategory &&
                            selectedState &&
                            selectedCity &&
                            selectedSituation != ""
                          ) {
                            const { data } = await api.marketing.notify({
                              filter: {
                                animal_category: selectedAnimalCategory,
                                address_state: selectedState,
                                address_city: selectedCity,
                                situation: selectedSituation,
                              },
                              message: messageValue,
                              name: username,
                            });
                            PopupShow({
                              title: "PushUp enviado com sucesso!",
                              description: "",
                              cancelText: "",
                              confirmText: "ir para lista",
                              style: {
                                close: {
                                  display: "none",
                                },
                              },
                              onCancel: async () => {},
                              onConfirm: async () => {
                                setUpdate("pushUp" + new Date());
                                GoBack();
                              },
                            });
                          } else {
                            PopupShow({
                              title: "Edição não permitida!",
                              description: "",
                              cancelText: "",
                              style: {
                                close: {
                                  display: "none",
                                },
                              },
                              confirmText: "voltar para lista",
                              onCancel: async () => {},
                              onConfirm: async () => {
                                GoBack();
                              },
                            });
                          }
                        },
                      }),
                    ],
                  },
                ],
              },
            ],
          })}
        </Fade>
      </div>
    ),
  };
};
