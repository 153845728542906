// Init Page para valida algumas infoamções e selcionar para qual pagina direcionar..

// verificar historico, ou url com parametros..

import { useEffect, useState } from "react";

import api from "service";
import { ShowPage } from "context/showPage";
import { ShowPopup } from "context/popup";
import ListView from "components/web/view/list";
import PopUp from "components/web/popup";
import Button from "components/web/button";

import Theme from "theme";

// importar pagina padrao e criar variveis para add e listar...
// import AddClient from "pages/admin/client/add";
// import ListClient from "pages/admin/client/list";

import Icon from "components/web/icon";

export default () => {
  const { PageInfo, GoPage } = ShowPage();
  const { PopupShow, PopupClose } = ShowPopup();

  useEffect(async () => {
    // GoPage("ListClient");
    // GoPage("AddClient");
    // GoPage("ListEmployee");
  }, []);

  //Lista de Usuários gerais
  return {
    component: "container",
    style: {
      flex: 1,
      display: PageInfo.show === "user-actives" ? "flex" : "none",
      //backgroundColor: "red",
    },
    children: [
      {
        component: "container",
        style: {
          width: "100%",
          flex: 1,
        },
        children: [
          ListView({
            id: "user-list",
            title: "Usuários - Ativos",
            orderby: "last_access",
            filterby_key: "last_access",
            filterby_op: "$gt",
            filterby_val: 0,
            style: {
              cel: (props) => {
                switch (props.col) {
                  case "pets": {
                    return {
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: 24,
                      width: "100%",
                      borderRadius: 4,
                      padding: 8,
                      fontWeight: "400",
                    };
                  }
                  case "name": {
                    return {
                      display: "flex",
                      color: Theme.colors.secondary,
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: 4,
                      fontSize: Theme.fontSizes.row,
                      fontWeight: "400",
                      cursor: "pointer",
                      border: "none",
                    };
                  }
                  case "address": {
                    return {
                      display: "flex",
                      color: Theme.colors.secondary,
                      fontSize: Theme.fontSizes.row,
                      cursor: "pointer",
                    };
                  }
                  case "access": {
                    return {
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      height: 24,
                      maxWidth: "90%",
                      borderRadius: 4,
                      padding: 8,
                      fontSize: Theme.fontSizes.row,
                      fontWeight: "600",
                      color:
                        props.item[props.col].code === 0
                          ? "rgba(75, 85, 99, 1)"
                          : props.item[props.col].code === 1
                          ? "rgba(5, 150, 105, 1)"
                          : props.item[props.col].code === 2
                          ? "rgba(75, 85, 99, 1)"
                          : props.item[props.col].code === 3
                          ? "rgba(173, 195, 237, 0)"
                          : props.item[props.col].code === 4
                          ? "rgba(5, 89, 150, 1)"
                          : props.item[props.col].code === 5
                          ? "rgba(150, 109, 5, 1)"
                          : "transparent",
                      backgroundColor:
                        props.item[props.col].code === 0
                          ? "rgba(229, 231, 235, 1)"
                          : props.item[props.col].code === 1
                          ? "rgba(167, 243, 208, 1)"
                          : props.item[props.col].code === 2
                          ? "rgba(229, 231, 235, 1)"
                          : props.item[props.col].code === 3
                          ? "rgba(173, 195, 237, 0)"
                          : props.item[props.col].code === 4
                          ? "rgba(173, 195, 237, 1)"
                          : props.item[props.col].code === 5
                          ? "rgba(255, 209, 113, 1)"
                          : "transparent",
                    };
                  }
                  default:
                    return {
                      // fontSize: Theme.fontSizes.row,
                      // fontWeight: "400",
                      fontWeight: "normal",
                    };
                }
              },
              row: {
                width: "100%",
                fontWeight: "normal",
                display: "flex",
                fontSize: Theme.fontSizes.row,
                fontFamily: Theme.fontFamilies.primary,
                minHeight: 42,
                maxHeight: 42,
                alignItems: "center",
                justifyContent: "center",
                padding: 8,
              },
              col: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: Theme.fontSizes.col,
                borderRight: "none",
                fontWeight: "bold",
                padding: 8,
                minHeight: 32,
                maxHeight: 32,
              },
            },
            col: {
              name: {
                title: "Usuário",
                style: {
                  flex: 2,
                },
                onClick: async (info) => {
                  PopupShow({
                    title: "",
                    description: "",
                    style: {
                      box: {
                        width: "auto",
                        height: "auto",
                        alignItems: "center",
                      },
                      close: {
                        display: "none",
                      },
                    },
                    item: [
                      {
                        component: "container",
                        style: {
                          display: "flex",
                          width: "100%",
                          height: "100%",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          padding: 8,
                          gap: 8,
                        },
                        children: [
                          //Nome cliente
                          {
                            component: "container",
                            style: {
                              display: "flex",
                              width: "100%",
                              flexDirection: "row",
                              gap: 4,
                              alignItems: "flex-start",
                            },
                            children: [
                              {
                                component: "text",
                                text: "Nome: ",
                                style: {
                                  fontSize: 14,
                                  fontWeight: "600",
                                  fontFamily: "Poppins",
                                },
                              },
                              {
                                component: "text",
                                text: info?.name || "",
                                style: {
                                  fontSize: 14,
                                  fontWeight: "400",
                                  fontFamily: "Poppins",
                                },
                              },
                            ],
                          },
                          //Telefone
                          {
                            component: "container",
                            style: {
                              display: "flex",
                              width: "100%",
                              flexDirection: "row",
                              gap: 4,
                              alignItems: "flex-start",
                            },
                            children: [
                              {
                                component: "text",
                                text: "Telefone: ",
                                style: {
                                  fontSize: 14,
                                  fontWeight: "600",
                                  fontFamily: "Poppins",
                                },
                              },
                              {
                                component: "text",
                                text: info?.phone || "",
                                style: {
                                  fontSize: 14,
                                  fontWeight: "400",
                                  fontFamily: "Poppins",
                                },
                              },
                            ],
                          },
                          //Creditos
                          {
                            component: "container",
                            style: {
                              display: "flex",
                              width: "100%",
                              flexDirection: "row",
                              gap: 4,
                              alignItems: "flex-start",
                            },
                            children: [
                              {
                                component: "text",
                                text: "Créditos: ",
                                style: {
                                  fontSize: 14,
                                  fontWeight: "600",
                                  fontFamily: "Poppins",
                                },
                              },
                              {
                                component: "text",
                                text: "R$ " + info?.credit || "",
                                style: {
                                  fontSize: 14,
                                  fontWeight: "400",
                                  fontFamily: "Poppins",
                                },
                              },
                            ],
                          },
                          //Registro
                          {
                            component: "container",
                            style: {
                              display: "flex",
                              width: "100%",
                              flexDirection: "row",
                              gap: 4,
                              alignItems: "flex-start",
                            },
                            children: [
                              {
                                component: "text",
                                text: "Registro: ",
                                style: {
                                  fontSize: 14,
                                  fontWeight: "600",
                                  fontFamily: "Poppins",
                                },
                              },
                              {
                                component: "text",
                                text: info?.date,
                                style: {
                                  fontSize: 14,
                                  fontWeight: "400",
                                  fontFamily: "Poppins",
                                },
                              },
                            ],
                          },
                          //ultimo acesso
                          {
                            component: "container",
                            style: {
                              display: "flex",
                              width: "100%",
                              flexDirection: "row",
                              gap: 4,
                              alignItems: "flex-start",
                            },
                            children: [
                              {
                                component: "text",
                                text: "Último acesso: ",
                                style: {
                                  fontSize: 14,
                                  fontWeight: "600",
                                  fontFamily: "Poppins",
                                },
                              },
                              {
                                component: "text",
                                text: info?.last_access,
                                style: {
                                  fontSize: 14,
                                  fontWeight: "400",
                                  fontFamily: "Poppins",
                                },
                              },
                            ],
                          },
                          //PetLabel
                          {
                            component: "text",
                            text: "Pet",
                            style: {
                              display: info.pets?.length > 0 ? "flex" : "none",
                              width: "100%",
                              fontSize: 16,
                              fontWeight: "600",
                              fontFamily: "Poppins",
                              marginTop: 12,
                            },
                          },
                          //pets
                          {
                            component: "container",
                            style: {
                              display: "flex",
                              alignItems: "flex-start",
                              justifyContent: "flex-start",
                              flexDirection: "column",
                              width: "auto",
                              maxHeight: "22vh",
                              overflowY: "auto",
                              gap: 12,
                              padding: 8,
                            },
                            children: info.pets?.map((pet) => {
                              return {
                                component: "container",
                                style: {
                                  display: "flex",
                                  width: "100%",
                                  flexDirection: "row",
                                  gap: 8,
                                  alignItems: "center",
                                  justifyContent: "flex-start",
                                  padding: 4,
                                  paddingRight: 32,
                                  borderRadius: 8,
                                  border: "1px solid gray",
                                  boxShadow:
                                    "1px 1px 10px rgba(211, 211, 211, 1)",
                                },
                                children: [
                                  //logo
                                  {
                                    component: "image",
                                    style: {
                                      width: 64,
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      backgroundColor:
                                        Theme.colors.gray.background,
                                      borderRadius: 8,
                                      padding: 4,
                                    },
                                    src: pet?.picture_thumb,
                                  },
                                  // info
                                  {
                                    component: "container",
                                    style: {
                                      display: "flex",
                                      width: "100%",
                                      height: "100%",
                                      flexDirection: "column",
                                      gap: 8,
                                    },
                                    children: [
                                      //name
                                      {
                                        component: "text",
                                        text: pet?.name || "",
                                        style: {
                                          width: "100%",
                                          textAlign: "flex-start",
                                          fontFamily: "Poppins",
                                          fontWeight: "600",
                                          fontSize: 16,
                                        },
                                      },
                                      // description
                                      // {
                                      //   component: "container",
                                      //   style: {
                                      //     display: "flex",
                                      //     alignItems: "center",
                                      //     justifyContent: "flex-start",
                                      //     flexDirection: "row",
                                      //     width: "100%",
                                      //     gap: 4,
                                      //     marginTop: 4,
                                      //   },
                                      //   children: [
                                      //     {
                                      //       component: "text",
                                      //       text: pet.breed.name,
                                      //       style: Theme.text.label,
                                      //     },
                                      //     {
                                      //       component: "text",
                                      //       style: {
                                      //         borderRadius: 4,
                                      //         height: 4,
                                      //         width: 4,
                                      //         backgroundColor:
                                      //           Theme.colors.black.full,
                                      //       },
                                      //     },
                                      //     {
                                      //       component: "text",
                                      //       text: pet.size.name,
                                      //       style: Theme.text.label,
                                      //     },
                                      //     {
                                      //       component: "text",
                                      //       style: {
                                      //         borderRadius: 4,
                                      //         height: 4,
                                      //         width: 4,
                                      //         backgroundColor:
                                      //           Theme.colors.black.full,
                                      //       },
                                      //     },
                                      //     {
                                      //       component: "text",
                                      //       text: pet.neutered_animal
                                      //         ? "Castrado"
                                      //         : "Normal",
                                      //       style: Theme.text.label,
                                      //     },
                                      //     {
                                      //       component: "text",
                                      //       style: {
                                      //         borderRadius: 4,
                                      //         height: 4,
                                      //         width: 4,
                                      //         backgroundColor:
                                      //           Theme.colors.black.full,
                                      //       },
                                      //     },
                                      //     {
                                      //       component: "text",
                                      //       text: pet.animal_category.name,
                                      //       style: Theme.text.label,
                                      //     },
                                      //   ],
                                      // },
                                      //info
                                      {
                                        component: "container",
                                        style: {
                                          display: "flex",
                                          width: "100%",
                                          alignItems: "center",
                                          flexDirection: "row",
                                          gap: 8,
                                        },
                                        children: [
                                          //raça
                                          {
                                            component: "text",
                                            text: pet?.breed.name || "",
                                            style: {
                                              textAlign: "flex-start",
                                              fontFamily: "Poppins",
                                              fontWeight: "400",
                                              color: "gray",
                                              fontSize: 12,
                                            },
                                          },
                                          //•
                                          {
                                            component: "text",
                                            text: "•",
                                            style: {
                                              color: "gray",
                                            },
                                          },
                                          //kg
                                          {
                                            component: "text",
                                            text: pet?.size.name || "",
                                            style: {
                                              textAlign: "flex-start",
                                              fontFamily: "Poppins",
                                              fontWeight: "400",
                                              color: "gray",
                                              fontSize: 12,
                                            },
                                          },
                                          //•
                                          {
                                            component: "text",
                                            text: "•",
                                            style: {
                                              color: "gray",
                                            },
                                          },
                                          //castração
                                          {
                                            component: "text",
                                            text: pet.neutered_animal
                                              ? "Castrado"
                                              : "Normal",
                                            style: {
                                              textAlign: "flex-start",
                                              fontFamily: "Poppins",
                                              fontWeight: "400",
                                              color: "gray",
                                              fontSize: 12,
                                            },
                                          },
                                          //•
                                          {
                                            component: "text",
                                            text: "•",
                                            style: {
                                              color: "gray",
                                            },
                                          },
                                          //type_animal
                                          {
                                            component: "text",
                                            text: pet.animal_category.name,
                                            style: {
                                              textAlign: "flex-start",
                                              fontFamily: "Poppins",
                                              fontWeight: "400",
                                              color: "gray",
                                              fontSize: 12,
                                            },
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              };
                            }),
                          },
                        ],
                      },
                    ],
                    buttons: [
                      {
                        component: "container",
                        style: {
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          gap: 8,
                          marginTop: 12,
                        },
                        children: [
                          //btn fechar
                          Button({
                            label: "Fechar",
                            style: {
                              button: {
                                backgroundColor: "white",
                                width: "100%",
                                alignItems: "center",
                                height: "42%",
                                color: "blue",
                                borderRadius: 6,
                                border: "none",
                              },
                              label: {
                                fontFamily: Theme.fontFamilies.primary,
                                fontSize: 18,
                              },
                            },
                            onPress: async () => {
                              PopupClose();
                            },
                          }),
                        ],
                      },
                    ],
                  });
                },
              },
              address: {
                key: ["address", "default", "city"],
                title: "Endereço",
                style: {
                  flex: 2,
                },
                onClick: async (info) => {
                  PopupShow({
                    description: "",
                    style: {
                      box: {
                        width: "42%",
                        alignItems: "center",
                      },
                      close: {
                        display: "none",
                      },
                    },
                    item: [
                      {
                        component: "container",
                        style: {
                          display: "flex",
                          alignItems: "flex-start",
                          justifyContent: "flex-start",
                          flexDirection: "column",
                          width: "100%",
                          gap: 8,
                        },
                        children: [
                          // Nome: Pedro Alcantara
                          // E-mail: Pedro@voke.com.brLocalização
                          // Estado: SP
                          // Cidade: São Paulo
                          // Endereço: Filipinas 55 BComplemento: Casa 1
                          // Cep: 05083-120
                          //Nome cliente
                          {
                            component: "container",
                            style: {
                              display: "flex",
                              width: "100%",
                              flexDirection: "row",
                              gap: 4,
                              alignItems: "flex-start",
                            },
                            children: [
                              {
                                component: "text",
                                text: "Nome: ",
                                style: {
                                  fontSize: 14,
                                  fontWeight: "600",
                                  fontFamily: "Poppins",
                                },
                              },
                              {
                                component: "text",
                                text: info.name || "",
                                style: {
                                  fontSize: 14,
                                  fontWeight: "400",
                                  fontFamily: "Poppins",
                                },
                              },
                            ],
                          },
                          //E-mail
                          {
                            component: "container",
                            style: {
                              display: "flex",
                              width: "100%",
                              flexDirection: "row",
                              gap: 4,
                              alignItems: "flex-start",
                            },
                            children: [
                              {
                                component: "text",
                                text: "E-mail: ",
                                style: {
                                  fontSize: 14,
                                  fontWeight: "600",
                                  fontFamily: "Poppins",
                                },
                              },
                              {
                                component: "text",
                                text: info?.email || "",
                                style: {
                                  fontSize: 14,
                                  fontWeight: "400",
                                  fontFamily: "Poppins",
                                },
                              },
                            ],
                          },
                          //Estado
                          {
                            component: "container",
                            style: {
                              display: "flex",
                              width: "100%",
                              flexDirection: "row",
                              gap: 4,
                              alignItems: "flex-start",
                            },
                            children: [
                              {
                                component: "text",
                                text: "Estado: ",
                                style: {
                                  fontSize: 14,
                                  fontWeight: "600",
                                  fontFamily: "Poppins",
                                },
                              },
                              {
                                component: "text",
                                text: info.address?.default?.state || "",
                                style: {
                                  fontSize: 14,
                                  fontWeight: "400",
                                  fontFamily: "Poppins",
                                },
                              },
                            ],
                          },
                          //Cidade
                          {
                            component: "container",
                            style: {
                              display: "flex",
                              width: "100%",
                              flexDirection: "row",
                              gap: 4,
                              alignItems: "flex-start",
                            },
                            children: [
                              {
                                component: "text",
                                text: "Cidade: ",
                                style: {
                                  fontSize: 14,
                                  fontWeight: "600",
                                  fontFamily: "Poppins",
                                },
                              },
                              {
                                component: "text",
                                text: info.address?.default?.city || "",
                                style: {
                                  fontSize: 14,
                                  fontWeight: "400",
                                  fontFamily: "Poppins",
                                },
                              },
                            ],
                          },
                          //Endereço
                          {
                            component: "container",
                            style: {
                              display: "flex",
                              width: "100%",
                              flexDirection: "row",
                              gap: 4,
                              alignItems: "flex-start",
                            },
                            children: [
                              {
                                component: "text",
                                text: "Endereço: ",
                                style: {
                                  fontSize: 14,
                                  fontWeight: "600",
                                  fontFamily: "Poppins",
                                },
                              },
                              {
                                component: "text",
                                text: info.address?.default?.street || "",
                                style: {
                                  fontSize: 14,
                                  fontWeight: "400",
                                  fontFamily: "Poppins",
                                },
                              },
                            ],
                          },
                          //Complemento
                          {
                            component: "container",
                            style: {
                              display: "flex",
                              width: "100%",
                              flexDirection: "row",
                              gap: 4,
                              alignItems: "flex-start",
                            },
                            children: [
                              {
                                component: "text",
                                text: "Complemento: ",
                                style: {
                                  fontSize: 14,
                                  fontWeight: "600",
                                  fontFamily: "Poppins",
                                },
                              },
                              {
                                component: "text",
                                text: info.address?.default?.complement || "",
                                style: {
                                  fontSize: 14,
                                  fontWeight: "400",
                                  fontFamily: "Poppins",
                                },
                              },
                            ],
                          },
                          //Cep
                          {
                            component: "container",
                            style: {
                              display: "flex",
                              width: "100%",
                              flexDirection: "row",
                              gap: 4,
                              alignItems: "flex-start",
                            },
                            children: [
                              {
                                component: "text",
                                text: "Cep: ",
                                style: {
                                  fontSize: 14,
                                  fontWeight: "600",
                                  fontFamily: "Poppins",
                                },
                              },
                              {
                                component: "text",
                                text: info.address?.default?.code || "",
                                style: {
                                  fontSize: 14,
                                  fontWeight: "400",
                                  fontFamily: "Poppins",
                                },
                              },
                            ],
                          },
                          // Estado
                          // {
                          //   component: "container",
                          //   style: {
                          //     display: "flex",
                          //     flexDirection: "row",
                          //     width: "100%",
                          //     alignItems: "center",
                          //     justifyContent: "flex-start",
                          //     padding: 2,
                          //     gap: 2,
                          //   },
                          //   children: [
                          //     {
                          //       component: "text",
                          //       text: "Estado: ",
                          //       style: Theme.text.title,
                          //     },
                          //     {
                          //       component: "text",
                          //       text: info.address?.default?.state || "",
                          //       style: Theme.text.label,
                          //     },
                          //   ],
                          // },
                          // Cidade
                          // {
                          //   component: "container",
                          //   style: {
                          //     display: "flex",
                          //     flexDirection: "row",
                          //     width: "100%",
                          //     alignItems: "center",
                          //     justifyContent: "flex-start",
                          //     padding: 2,
                          //     gap: 2,
                          //   },
                          //   children: [
                          //     {
                          //       component: "text",
                          //       text: "Cidade: ",
                          //       style: Theme.text.title,
                          //     },
                          //     {
                          //       component: "text",
                          //       text: info.address?.default?.city || "",
                          //       style: Theme.text.label,
                          //     },
                          //   ],
                          // },
                          // Endereço
                          // {
                          //   component: "container",
                          //   style: {
                          //     display: "flex",
                          //     flexDirection: "row",
                          //     width: "100%",
                          //     alignItems: "center",
                          //     justifyContent: "flex-start",
                          //     padding: 2,
                          //     gap: 2,
                          //   },
                          //   children: [
                          //     {
                          //       component: "text",
                          //       text: "Endereço: ",
                          //       style: Theme.text.title,
                          //     },
                          //     {
                          //       component: "text",
                          //       text: info.address?.default?.street || "",
                          //       style: Theme.text.label,
                          //     },
                          //   ],
                          // },
                          // Complemento
                          // {
                          //   component: "container",
                          //   style: {
                          //     display: "flex",
                          //     flexDirection: "row",
                          //     width: "100%",
                          //     alignItems: "center",
                          //     justifyContent: "flex-start",
                          //     padding: 2,
                          //     gap: 2,
                          //   },
                          //   children: [
                          //     {
                          //       component: "text",
                          //       text: "Complemento: ",
                          //       style: Theme.text.title,
                          //     },
                          //     {
                          //       component: "text",
                          //       text: info.address?.default?.complement || "",
                          //       style: Theme.text.label,
                          //     },
                          //   ],
                          // },
                          // Cep
                          // {
                          //   component: "container",
                          //   style: {
                          //     display: "flex",
                          //     flexDirection: "row",
                          //     width: "100%",
                          //     alignItems: "center",
                          //     justifyContent: "flex-start",
                          //     padding: 2,
                          //     gap: 2,
                          //   },
                          //   children: [
                          //     {
                          //       component: "text",
                          //       text: "Cep: ",
                          //       style: Theme.text.title,
                          //     },
                          //     {
                          //       component: "text",
                          //       text: info.address?.default?.code || "",
                          //       style: Theme.text.label,
                          //     },
                          //   ],
                          // },
                        ],
                      },
                    ],
                    buttons: [
                      {
                        component: "container",
                        style: {
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                          justifyContent: "center",
                          flexDirection: "column",
                          gap: 8,
                          marginTop: 12,
                        },
                        children: [
                          //btn fechar
                          Button({
                            label: "Fechar",
                            style: {
                              button: {
                                backgroundColor: "white",
                                width: "100%",
                                alignItems: "center",
                                height: "42%",
                                color: "blue",
                                borderRadius: 6,
                                border: "none",
                              },
                              label: {
                                fontFamily: Theme.fontFamilies.primary,
                                fontSize: 18,
                              },
                            },
                            onPress: async () => {
                              PopupClose();
                            },
                          }),
                        ],
                      },
                    ],
                  });
                },
              },
              pets: {
                // key: ["", ""],
                title: "Pets",
                condition: ({ pets }) => {
                  if (pets.length === 0) {
                    return "";
                  }
                  let cats = 0;
                  let dogs = 0;
                  pets.map((pet) => {
                    if (pet.animal_category.code === 1) dogs++;
                    else if (pet.animal_category.code === 2) cats++;
                  });
                  if (cats + dogs > 2) {
                    return pets.length + " Pets";
                  }
                  return pets.length;
                  // return "Inativo";
                },
              },
              business: {
                title: "Fonte",
              },
              date: {
                title: "Data de cadastro",
                style: {
                  flex: 1,
                },
              },
              last_access: {
                title: "Último acesso",
                style: {
                  flex: 1,
                },
              },
              access: {
                title: "Situação",
                style: {
                  flex: 2,
                },
                condition: (param) => {
                  return param?.access?.name;
                },
              },
            },
            item: api.people.listAll,
          }),
        ],
      },
    ],
  };
};
