import { useEffect, useState } from "react";
import { ShowPage } from "context/showPage";
import { Context } from "context/data";
import Fade from "react-reveal/Fade";
import { Render } from "@8_dev/sjson-web";
import { Howl, Howler } from "howler";

import Theme from "theme";

import api from "service";

//images
import ImagePerson from "assets/images/bcg/petFriendly.png";
import Logo from "assets/images/camada_1.png";

//components
import Access from "components/web/access";

export default (props) => {
  const { Data, Set } = Context();

  useEffect(async () => {}, []);

  return {
    render: (
      <div style={{ display: !Data.user?.status ? "flex" : "none" }}>
        <Fade>
          {Render(
            Access({
              title: "Painel de controle",
            })
          )}
        </Fade>
      </div>
    ),
  };
};
// {
//             component: "container",
//             style: {
//               width: "100%",
//               height: "100%",
//               position: "absolute",
//               backgroundColor: Theme.colors.purple.background,
//               background:
//                 "linear-gradient(to left, rgba(0, 51, 51, 0.8), rgba(255, 255, 255, 0.8)80%)",
//               display: "flex",
//               alignItems: "center",
//               justifyContent: "center",
//             },
//             children: [
//               {
//                 component: "container",
//                 style: {
//                   display: "flex",
//                   backgroundColor: "rgba(255,255,255,0.64)",
//                   border: "1px solid green",
//                   flexDirection: "row",
//                   minWidth: "52vw",
//                   minHeight: "52vh",
//                   borderRadius: 16,
//                 },
//                 children: [
//                   // form login
//                   {
//                     component: "container",
//                     style: {
//                       width: "100%",
//                       minHeight: "54vh",
//                       padding: 16,
//                       gap: 8,
//                       display: "flex",
//                       flexDirection: "column",
//                       alignItems: "center",
//                       justifyContent: "center",
//                     },
//                     children: [
//                       {
//                         component: "text",
//                         style: {
//                           display: "flex",
//                           width: "100%",
//                           alignItems: "center",
//                           justifyContent: "center",
//                           marginBottom: 32,
//                           ...Theme.text.login,
//                           fontSize: "2.2vh",
//                         },
//                         text: "Faça login como admin",
//                       },
//                       // username
//                       inptUsername,
//                       // password
//                       inptPassword,
//                       {
//                         component: "container",
//                         style: {
//                           ...Theme.button.login,
//                           padding: 18,
//                           fontSize: 12,
//                           marginTop: 12,
//                           backgroundColor: Theme.colors.green.light,
//                         },
//                         onClick: async () => {
//                           const { data } = await api.auth.login({
//                             login_code: username,
//                             login_password: password,
//                           });
//                           await sleep(500);
//                           if (data?.status === true) {
//                             // salvar token no localstorage

//                             await localStorage.setItem(
//                               "token",
//                               data.data.token
//                             );

//                             soundPlay(DogAudio, 1);

//                             await sleep(500);

//                             Set({
//                               ...Data,
//                               user: data.data.user,
//                             });
//                             GoInitPage();
//                           } else {
//                             soundPlay(DogAudio, 2);
//                           }
//                         },
//                         children: [
//                           {
//                             component: "text",
//                             style: { ...Theme.text.button, fontSize: 16 },
//                             text: "Entrar",
//                           },
//                         ],
//                       },
//                     ],
//                   },

//                   //Card a direita
//                   {
//                     component: "container",
//                     style: {
//                       display: "flex",
//                       minWidth: "28vw",
//                       maxWidth: "28vw",
//                       minHeight: "100%",
//                       marginLeft: "auto",
//                       flexDirection: "column",
//                       justifyContent: "center",
//                       alignItems: "center",
//                       padding: 38,
//                       borderTopLeftRadius: 128,
//                       borderBottomLeftRadius: 100,
//                       borderTopRightRadius: 16,
//                       borderBottomRightRadius: 16,
//                       backgroundColor: Theme.colors.green.light,
//                     },
//                     children: [
//                       //Logo
//                       {
//                         component: "container",
//                         style: {
//                           marginTop: "10%",
//                           display: "flex",
//                           alignItems: "center",
//                           justifyContent: "center",
//                         },
//                         children: [
//                           {
//                             render: (
//                               <img
//                                 style={{
//                                   width: "70%",
//                                 }}
//                                 src={Logo}
//                               />
//                             ),
//                           },
//                         ],
//                       },

//                       //Logo 2
//                       {
//                         component: "container",
//                         style: {
//                           marginTop: "10%",
//                           display: "flex",
//                           alignItems: "center",
//                           justifyContent: "center",
//                         },
//                         children: [
//                           {
//                             render: (
//                               <img
//                                 style={{
//                                   width: "70%",
//                                 }}
//                                 src={ImagePerson}
//                               />
//                             ),
//                           },
//                         ],
//                       },
//                     ],
//                   },
//                 ],
//               },
//             ],
// }
