import Theme from "theme";
import React, { useState } from "react";
import Fade from "react-reveal/Fade";
import { Render } from "@8_dev/sjson-web";

//api
import api from "service";

export default ({ content, setContent }) => {
  return {
    render: (
      <Fade>
        {Render({
          component: "container",
          style: {
            display: content === "EmailSent" ? "flex" : "none",
            backgroundColor: "white",
            width: "28vw",
            height: "auto",
            marginTop: 32,
            borderRadius: 4,
            padding: "4%",
            border: "1px solid #E6E6E6",
            flexDirection: "column",
            gap: 28,
          },
          children: [
            //titleForm
            {
              component: "text",
              text: "Recuperação de senha",
              style: {
                width: "100%",
                fontFamily: "Poppins",
                textAlign: "flex-start",
                fontWeight: "600",
                fontSize: 24,
                color: "#05332F",
              },
            },
            //text email enviado
            {
              component: "text",
              text:
                "Enviamos um email para" +
                " " +
                "{email}" +
                " " +
                "com um link para redefinição de senha.",
              style: {
                fontSize: "1vw",
                fontFamily: "Poppins",
                color: "#05332F",
              },
            },
            //text checar acesso ao email.
            {
              component: "text",
              text: "Caso não tenha acesso à esse e-mail, entre em contato com seu gerente comercial.",
              style: {
                fontSize: "1vw",
                fontFamily: "Poppins",
                color: "#05332F",
              },
            },
            //reenviar email
            {
              component: "text",
              text: "Não recebi o e-mail",
              style: {
                fontSize: 16,
                width: "50%",
                fontFamily: "Poppins",
                cursor: "pointer",
                color: "rgba(0, 166, 96, 1)",
              },
              onClick: () => {
                //reenviar o código para o email.
                setContent("ResetPassword");
              },
            },
          ],
        })}
      </Fade>
    ),
  };
};
