import { Render } from "@8_dev/sjson-web";
import Theme from "theme";
import api from "service";
// import { Render } from "render";

import { useEffect, useState } from "react";
import Fade from "react-reveal/Fade";
import { Context } from "context/data";
import { ShowPage } from "context/showPage";
import { ShowPopup } from "context/popup";

import Navbar from "components/web/navbar";

import UserIcon from "assets/images/menu/icon-people.svg";
import IconHome from "assets/images/menu/home.svg";
import OrderIcon from "assets/images/menu/icon-order.svg";
import BusinessIcon from "assets/images/menu/icon-business.svg";
import ProviderIcon from "assets/images/menu/icon-provider.svg";
import MarketingIcon from "assets/images/menu/megafone.png";
import FinanceIcon from "assets/images/menu/currency.svg";

import Arrow from "assets/images/menu/arrowDown.svg";

import Home from "pages/home";
import Provider from "pages/provider";
import Business from "pages/business";
import Consumer from "pages/consumer";
import Order from "pages/order";
import Marketing from "pages/marketing";
import Financial from "pages/financial";
import Management from "pages/management"
 
export default () => {
  const { Data, Set } = Context();
  const { GoInitPage, GoPage, setUpdate } = ShowPage();
  const { PopupShow, PopupClose } = ShowPopup();

  useEffect(async () => {}, []);

  // let Pages = [];
  const Nav = {
    component: "container",
    style: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
    },
    children: [
      Navbar({
        item: [
          //Inicio
          {
            title: "Início",
            page: "Home",
            image: {
              src: IconHome,
            },
          },
          // Pedidos
          {
            title: "Pedidos",
            page: "order-buy",
            image: {
              src: OrderIcon,
            },
          },
          // Usuarios
          {
            title: "Usuários",
            page: "user-list",
            image: {
              src: UserIcon,
              srcArrow: Arrow,
            },
            children: [
              // Provider Add
              {
                title: "Ativos",
                page: "user-actives",
              },
            ],
          },
          // Business
          {
            title: "Empresas",
            page: "business-list",
            image: {
              src: BusinessIcon,
              srcArrow: Arrow,
              // name: 'user'
            },
            children: [
              // BusinessAdd
              {
                title: "Adicionar",
                page: "business-add",
              },
            ],
          },
          // Provider
          {
            title: "Parceiros",
            page: "provider-list",
            image: {
              src: ProviderIcon,
              srcArrow: Arrow,
            },
            children: [
              // Provider Add
              {
                title: "Adicionar",
                page: "provider-add",
              },
            ],
          },
          // Financeiro
          {
            title: "Financeiro",
            page: "finance-list",
            image: {
              src: FinanceIcon,
              srcArrow: Arrow,
            },
            children: [
              // Finance Indicators
              {
                title: "Indicadores",
                page: "finance-indicators",
              },
              //Finance summary
              {
                title: "Resumo",
                page: "summary-list",
              },
            ],
          },
          // Marketing
          {
            title: "Marketing",
            page: "marketing",
            style: {
              iconBox: {
                marginLeft: 4,
              },
            },
            image: {
              src: MarketingIcon,
              srcArrow: Arrow,
            },
            children: [
              // Provider Add
              // {
              //   title: "Pushups",
              //   page: "pushups-add",
              // },
              {
                title: "Pushups",
                page: "pushUp",
              },
            ],
          },
          //Configurações
          {
            title: "Configurações",
            page: "configuracoes",
            style: {
              iconBox: {
                marginLeft: 4,
              },
            },
            image: {
              src: MarketingIcon,
              srcArrow: Arrow,
            },
            children: [
              // User Management
              {
                title: "Gerenciar usuários",
                page: "user-management",
              },
            ],
          },
        ],
      }),
    ],
  };

  const PageStyle = {
    display: "flex",
    flexDirection: "row",
    width: "100vw",
    minWidth: "100vw",
    maxWidth: "100vw",
    height: "100vh",
    maxHeight: "100vh",
    minHeight: "100vh",
    overflow: "hidden",
  };

  const Pages = [
    Nav,
    ...Home(),
    ...Provider(),
    ...Business(),
    ...Consumer(),
    ...Order(),
    ...Marketing(),
    ...Financial(),
    ...Management(),

  ];
  return {
    component: "container",
    style: PageStyle,
    children: Pages,
  };
};
