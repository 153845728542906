import { Profiler, useEffect, useState } from "react";

import api from "service";
import { ShowPage } from "context/showPage";
import { ShowPopup } from "context/popup";
import AddView from "components/web/view/add";
import PopUp from "components/web/popup";
import { Render } from "@8_dev/sjson-web";
import Icon from "components/web/icon";

import { Context } from "context/data";

import Theme from "theme";

import Arrow from "assets/images/menu/arrowBack.svg";
// srcTopBar: Arrow,

export default (param) => {
  const { PageInfo, GoPage } = ShowPage();
  const { PopupShow, PopupClose } = ShowPopup();

  const { Data, Set } = Context();

  //Varieavel que vai receber o switch
  const [typeProvider, setTypeProvider] = useState(false);

  const [provider, setProvider] = useState({});

  //Nome cnpj Provider
  const [employeeNameProvider, setEmployeeNameProvider] = useState("");

  //Busa cep provider
  const [addrStateProvider, setAddrStateProvider] = useState("");
  const [addrCityProvider, setAddrCityProvider] = useState("");
  const [addrDistrictProvider, setAddrDistrictProvider] = useState("");
  const [addrStreetProvider, setAddrStreetProvider] = useState("");
  const [addrComplementProvider, setAddrComplementProvider] = useState("");

  useEffect(() => {
    // let user = PageInfo.param.info;
    // setProvider(PageInfo.param.info);
    setAddrStateProvider(PageInfo.param.info?.address.state);
    setAddrCityProvider(PageInfo.param.info?.address.city);
    setAddrDistrictProvider(PageInfo.param.info?.address.district);
    setAddrStreetProvider(PageInfo.param.info?.address.street);
    setAddrComplementProvider(PageInfo.param.info?.address.complement);

    setProvider(PageInfo.param.info);
  }, [PageInfo]);

  const [typePix, setTypePix] = useState("");

  const [enablePicture, setEnablePicture] = useState(false);

  console.log(provider);

  const inputStyle = {
    // maxHeight: 140,
    input: {
      border: "1px solid #bfbfbf",
      borderRadius: 4,
      padding: 4,
      fontSize: "1.5vh",
      maxWidth: "100%",
    },
    title: Theme.text.input.title,
    select: {
      border: "1px solid #bfbfbf",
      borderRadius: 4,
      padding: 4,
      fontSize: "2vh",
    },
  };

  useEffect(async () => {
    // if (PageInfo.show === "provider-edit")
    //   setProvider({ ...provider, company_icon: "" });
  }, []);

  //Editar parceiros
  return {
    component: "container",
    style: {
      display: PageInfo.show === "provider-edit" ? "flex" : "none",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      width: "100%",
      maxHeight: "100vh",
      overflowX: "auto",
      overflowY: "auto",
      flex: 1,
      zIndex: 1,
    },
    children: [
      AddView({
        direction: "row",
        title: "Edite seu parceiro",
        srcTopBar: Arrow,
        form: {
          onSave: api.utils.update,
          onCancel: () => {},
          func: (data, info, clear, goBack) => {
            if (data.data?.status == true) {
              PopupShow({
                title: "Parceiro editado com sucesso!",
                description: "",
                cancelText: "",
                confirmText: "ir para lista",
                onCancel: async () => {},
                onConfirm: async () => {
                  goBack();
                },
              });
            } else {
              PopupShow({
                title: "Edição não permitida!",
                description: "",
                cancelText: "",
                style: {
                  close: {
                    display: "none",
                  },
                },
                confirmText: "voltar para lista",
                onCancel: async () => {},
                onConfirm: async () => {
                  goBack();
                },
              });
            }
          },
          style: {
            main: {
              flexWrap: "wrap",
              overflow: "auto",
              maxWidth: "60%",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: 12,
              padding: "1%",
            },
            box: {
              1: {},
            },
            btn: {
              cancel: {},
              save: {},
              style: {
                maxWidth: "60%",
              },
            },
          },
          box: {
            //Informações pessoais
            1: {
              style: {
                padding: "2%",
                flex: 1,
                minWidth: "100%",
                display: "flex",
                flexDirection: "column",
                border: "1px solid #CBD5E1",
                borderRadius: 4,
                gap: 8,
              },
              input: [
                {
                  label: "Informações Gerais",
                  style: {
                    fontSize: Theme.fontSizes.form.title,
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    color: Theme.colors.blue.life,
                    width: "100%",
                    display: "flex",
                    marginBottom: 4,
                    height: 16,
                  },
                },
                //divInput
                {
                  type: "div",
                  style: {
                    flex: 1,
                    flexDirection: "row",
                    gap: 24,
                    alignItems: "flex-start",
                  },
                  children: [
                    //divName + email + tipo de cadastro
                    {
                      type: "div",
                      style: {
                        display: "flex",
                        flexDirection: "column",
                        gap: 8,
                      },
                      children: [
                        //Nome
                        {
                          id: "nickname",
                          label: "Nome Fantasia",
                          value: provider?.nickname,
                          disabled: true,
                          set: (nickname) => {
                            setProvider({ ...provider, nickname });
                          },
                          type: "input",
                          style: {
                            ...inputStyle,
                            input: {
                              backgroundColor: "rgba(227, 227, 227, 1)",
                            },
                          },
                          required: true,
                          // check: { min: 5 },
                        },
                        //email
                        {
                          id: "email",
                          label: "Email do responsável",
                          value: provider?.email,
                          set: (email) => {
                            setProvider({ ...provider, email });
                          },
                          type: "input",
                          style: inputStyle,
                          required: true,
                          // check: { min: 5 },
                        },
                        //Tipo de cadastro
                        {
                          id: "register_type",
                          keyid: "register_type",
                          label: "Tipo de cadastro",
                          type: "select",
                          // value: provider?.register_type,
                          // set: (register_type) => {
                          //   setProvider({
                          //     ...provider,
                          //     register_type,
                          //   });
                          // },
                          option: [
                            {
                              _id: 1,
                              name: "Fidelizado",
                            },
                            {
                              _id: 2,
                              name: "Cadastrado",
                            },
                          ],
                          style: {
                            ...inputStyle,
                            marginTop: "auto",
                          },
                          required: true,
                        },
                        //Prazo de repasse
                        {
                          id: "transfer_deadline",
                          keyid: "transfer_deadline",
                          label: "Prazo de repasse",
                          type: "select",
                          option: [
                            {
                              _id: 1,
                              name: "D + 1",
                            },
                            {
                              _id: 30,
                              name: "D + 30",
                            },
                          ],
                          style: {
                            ...inputStyle,
                          },
                          required: true,
                        },
                        //ID
                        {
                          id: "_id",
                          label: "",
                          type: "input",
                          style: {
                            display: "none",
                          },
                          required: true,
                        },
                        //Type
                        {
                          id: "type",
                          label: "",
                          value: "provider",
                          type: "input",
                          style: {
                            display: "none",
                          },
                          required: true,
                        },
                      ],
                    },
                    //divCpnj + senha + whatsApp
                    {
                      type: "div",
                      style: {
                        display: "flex",
                        flexDirection: "column",
                        gap: 8,
                      },
                      children: [
                        //inputCnpj
                        {
                          id: "code",
                          keyid: "code",
                          label: provider?.code?.length < 14 ? "CPF" : "CNPJ",
                          type: "input",
                          disabled: true,
                          style: {
                            ...inputStyle,
                            input: {
                              backgroundColor: "rgba(227, 227, 227, 1)",
                            },
                          },
                          check: { type: "string" },
                          mask:
                            provider?.code?.length < 14
                              ? "999.999.999-99"
                              : "99.999.999/9999-99",
                          // value: provider?.code,
                          // set: (code) => {
                          //   setProvider({ ...provider, code });
                          // },
                          required: true,
                          // check: { min: 5 },
                        },

                        //WhatsApp
                        {
                          id: "whatsapp",
                          label: "WhatsApp(opcional)",
                          type: "input",
                          value: provider?.whatsapp,
                          set: (whatsapp) => {
                            setProvider({ ...provider, whatsapp });
                          },
                          mask: "(99)99999-9999",
                          style: inputStyle,
                          required: true,
                          // check: { min: 5 },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            //localização
            2: {
              style: {
                padding: "2%",
                flex: 1,
                minWidth: "100%",
                display: "flex",
                flexDirection: "column",
                border: "1px solid #CBD5E1",
                borderRadius: 4,
                gap: 8,
              },
              input: [
                {
                  label: "Localização",
                  style: {
                    fontSize: Theme.fontSizes.form.title,
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    color: Theme.colors.blue.life,
                    width: "100%",
                    display: "flex",
                    marginBottom: 4,
                    height: 16,
                  },
                },
                //dibInputs
                {
                  type: "div",
                  style: {
                    flex: 1,
                    flexDirection: "row",
                    gap: 24,
                    alignItems: "flex-start",
                  },
                  children: [
                    //divCep + numero
                    {
                      type: "div",
                      style: {
                        display: "flex",
                        flexDirection: "column",
                        gap: 8,
                      },
                      children: [
                        //cep
                        {
                          id: "address_code",
                          keyid: "address.code",
                          mask: "99999 999",
                          value: provider?.address?.code,
                          set: async (value) => {
                            setProvider({
                              ...provider,
                              address: { ...provider?.address, code: value },
                            });
                            //update
                            if (value.length === 8) {
                              const { data } = await api.utils.addressCode({
                                query: { code: value },
                              });
                              if (data?.data?.cep) {
                                setAddrStreetProvider(data.data.street);
                                setAddrStateProvider(data.data.state);
                                setAddrCityProvider(data.data.city);
                                setAddrDistrictProvider(data.data.neighborhood);
                              }
                            } else {
                            }
                          },
                          label: "CEP",
                          type: "input",
                          style: inputStyle,
                          required: true,
                          // check: { min: 5 },
                        },
                        //numero
                        {
                          id: "address_number",
                          keyid: "address.number",
                          value: provider?.address?.number,
                          set: (number) => {
                            setProvider({
                              ...provider,
                              address: { ...provider?.address, number },
                            });
                          },
                          label: "Número",
                          type: "input",
                          format: "number",
                          style: inputStyle,
                          // mask: "999999",
                          required: true,
                        },
                        //nameCNPJ
                        {
                          id: "name",
                          value: employeeNameProvider,
                        },
                      ],
                    },
                    //divLogadouro + complemento
                    {
                      type: "div",
                      style: {
                        display: "flex",
                        flexDirection: "column",
                        gap: 8,
                      },
                      children: [
                        //rua
                        {
                          id: "address_street",
                          keyid: "address.street",
                          value: addrStreetProvider,
                          set: (street) => {
                            setAddrStreetProvider(street);
                            setProvider({
                              ...provider,
                              address: { ...provider?.address, street },
                            });
                          },
                          label: "Logradouro",
                          type: "input",
                          style: inputStyle,
                          required: true,
                          // check: { min: 5 },
                        },
                        //complemento
                        {
                          id: "address_complement",
                          keyid: "address.complement",
                          value: addrComplementProvider || "",
                          set: (complement) => {
                            setAddrComplementProvider(complement);
                            setProvider({
                              ...provider,
                              address: { ...provider?.address, complement },
                            });
                          },
                          label: "Complemento",
                          type: "input",
                          style: inputStyle,
                          required: false,
                          // check: { min: 5 },
                        },
                      ],
                    },
                    //divEstado + cidade + bairro
                    {
                      type: "div",
                      style: {
                        display: "flex",
                        flexDirection: "column",
                        gap: 8,
                      },
                      children: [
                        //estado
                        {
                          id: "address_state",
                          keyid: "address.state",
                          value: addrStateProvider,
                          set: (state) => {
                            setAddrStateProvider(state);
                            setProvider({
                              ...provider,
                              address: { ...provider?.address, state },
                            });
                          },
                          label: "Estado",
                          type: "input",
                          style: inputStyle,
                          required: true,
                          // check: { min: 5 },
                        },
                        //cidade
                        {
                          id: "address_city",
                          label: "Cidade",
                          keyid: "address.city",
                          value: addrCityProvider,
                          set: (city) => {
                            setAddrCityProvider(city);
                            setProvider({
                              ...provider,
                              address: { ...provider?.address, city },
                            });
                          },
                          type: "input",
                          style: inputStyle,
                          required: true,
                          // check: { min: 5 },
                        },
                        //bairro
                        {
                          id: "address_district",
                          label: "Bairro",
                          keyid: "address.district",
                          value: addrDistrictProvider,
                          set: (district) => {
                            setAddrDistrictProvider(district);
                            setProvider({
                              ...provider,
                              address: { ...provider?.address, district },
                            });
                          },
                          type: "input",
                          style: inputStyle,
                          required: true,
                          // check: { min: 5 },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            // Banco + tipo de chave + chave pix / Agência + Digito + Conta + Digito
            3: {
              style: {
                padding: "2%",
                flex: 1,
                minWidth: "100%",
                display: "flex",
                flexDirection: "column",
                border: "1px solid #CBD5E1",
                borderRadius: 4,
                gap: 8,
              },
              input: [
                {
                  label: "Dados bancários",
                  style: {
                    fontSize: Theme.fontSizes.form.title,
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    color: Theme.colors.blue.life,
                    width: "100%",
                    display: "flex",
                    marginBottom: 4,
                    height: 16,
                  },
                },
                // (Div) Banco + tipo de chave + chave pix
                {
                  type: "div",
                  style: {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    width: "100%",
                  },
                  children: [
                    //banco
                    {
                      id: "bank",
                      keyid: "bank_account.bank",
                      label: "Selecione o banco",
                      type: "select",
                      value:
                        provider?.bank_account?.bank?._id ||
                        provider?.bank_account?.bank,
                      set: (bank) => {
                        setProvider({
                          ...provider,
                          bank_account: {
                            ...provider?.bank_account,
                            bank,
                          },
                        });
                      },
                      option: api.utils.bank,
                      style: {
                        ...inputStyle,
                        marginTop: "auto",
                      },
                      required: false,
                    },

                    //tipo de chave
                    {
                      id: "pix_type",
                      keyid: "bank_account.pix_type",
                      label: "Tipo de chave",
                      type: "select",
                      value:
                        provider?.bank_account?.pix_type?._id ||
                        provider?.bank_account?.pix_type,
                      set: (pix_type) => {
                        setProvider({
                          ...provider,
                          bank_account: {
                            ...provider?.bank_account,
                            pix_type,
                          },
                        });
                      },
                      option: api.utils.pix_type,
                      func: (item) => {
                        setTypePix(item.name);
                      },
                      style: {
                        ...inputStyle,
                        marginTop: "auto",
                      },
                      required: true,
                    },

                    //Chave pix
                    {
                      id: "pix_key",
                      keyid: "bank_account.pix_key",
                      label: "Chave pix",
                      value: provider?.bank_account?.pix_key,
                      set: (pix_key) => {
                        setProvider({
                          ...provider,
                          bank_account: {
                            ...provider?.bank_account,
                            pix_key,
                          },
                        });
                      },
                      mask:
                        typePix === "cpf"
                          ? "999.999.999-99"
                          : typePix === "cnpj"
                          ? "99.999.999/9999-99"
                          : typePix === "telefone"
                          ? "(99)99999-9999"
                          : "",
                      type: "input",
                      style: inputStyle,
                      required: true,
                      // check: { min: 5 },
                    },
                  ],
                },
                //(Div) Agência + Digito_agencia + Conta + Digito_account
                {
                  type: "div",
                  style: {
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    width: "100%",
                  },
                  children: [
                    //Conta + Digito
                    {
                      type: "div",
                      style: {
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: "row",
                        maxWidth: "35%",
                      },
                      children: [
                        //agencia
                        {
                          id: "agency",
                          keyid: "bank_account.agency",
                          value: provider?.bank_account?.agency,
                          set: (value) => {
                            setProvider({
                              ...provider,
                              bank_account: {
                                ...provider?.bank_account,
                                agency: value,
                              },
                            });
                          },
                          label: "Agência",
                          type: "input",
                          style: {
                            ...inputStyle,
                            maxWidth: "60%",
                          },
                          required: true,
                          // check: { min: 5 },
                        },

                        //Digito_agencia
                        {
                          id: "agency_code",
                          keyid: "bank_account.agency_code",
                          value: provider?.bank_account?.agency_code,
                          set: (value) => {
                            setProvider({
                              ...provider,
                              bank_account: {
                                ...provider?.bank_account,
                                agency_code: value,
                              },
                            });
                          },
                          label: "Digito",
                          type: "input",
                          style: {
                            ...inputStyle,
                            maxWidth: "30%",
                          },
                          required: true,
                          // check: { min: 5 },
                        },
                      ],
                    },

                    //Conta + Digito
                    {
                      type: "div",
                      style: {
                        display: "flex",
                        alignItems: "flex-start",
                        flexDirection: "row",
                        maxWidth: "35%",
                        marginLeft: 12,
                      },
                      children: [
                        //Conta
                        {
                          id: "account",
                          keyid: "bank_account.account",
                          value: provider?.bank_account?.account,
                          set: (value) => {
                            setProvider({
                              ...provider,
                              bank_account: {
                                ...provider?.bank_account,
                                account: value,
                              },
                            });
                          },
                          label: "Conta",
                          type: "input",
                          style: {
                            ...inputStyle,
                            maxWidth: "60%",
                          },
                          required: true,
                          // check: { min: 5 },
                        },

                        //Digito_account
                        {
                          id: "account_code",
                          keyid: "bank_account.account_code",
                          value: provider?.bank_account?.account_code,
                          set: (value) => {
                            setProvider({
                              ...provider,
                              bank_account: {
                                ...provider?.bank_account,
                                account_code: value,
                              },
                            });
                          },
                          label: "Digito",
                          type: "input",
                          style: {
                            ...inputStyle,
                            maxWidth: "30%",
                          },
                          required: true,
                          // check: { min: 5 },
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            //upload
            4: {
              style: {
                padding: "2%",
                flex: 1,
                minWidth: "100%",
                display: "flex",
                flexDirection: "column",
                border: "1px solid #CBD5E1",
                borderRadius: 4,
                gap: 8,
              },
              input: [
                {
                  label: "Logotipo do parceiro",
                  style: {
                    fontSize: Theme.fontSizes.form.title,
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    color: Theme.colors.blue.life,
                    width: "100%",
                    display: "flex",
                    marginBottom: 4,
                    height: 16,
                  },
                },
                //divInput
                {
                  type: "div",
                  style: {
                    width: "100%",
                    justifyContent: "center",
                    gap: 60,
                    flexDirection: "row",
                  },
                  children: [
                    {
                      id: "company_icon",
                      keyid: "company_icon_thumb",
                      title: "",
                      label: "Clique para fazer o upload",
                      type: "file",
                      value: provider?.company_icon_thumb,
                      func: (company_icon_thumb) => {
                        setProvider({
                          ...provider,
                          company_icon_thumb,
                        });
                      },
                      icon: "",
                      required: true,
                      style: {
                        title: {
                          fontSize: Theme.fontSizes.form.title,
                          fontFamily: "Poppins",
                          fontWeight: "bold",
                          color: Theme.colors.secondary,
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                        },
                        label: {
                          fontSize: Theme.fontSizes.form.title,
                          fontFamily: "Poppins",
                          color: "blue",
                          textAlign: "center",
                        },
                        box: {
                          backgroundColor: Theme.colors.blue.light,
                          padding: 8,
                          borderRadius: 4,
                          alignItems: "center",
                          flexDirection: "center",
                        },
                      },
                      // check: { min: 5 },
                    },
                    // {
                    //   type: "image",
                    //   src: provider?.company_icon_thumb,
                    //   size: 24,
                    //   style: {
                    //     iconBox: {
                    //       display: "flex",
                    //       width: 64,
                    //       height: 64,
                    //       borderRadius: "50%",
                    //     },
                    //   },
                    // },
                  ],
                },
              ],
            },
          },
        },
        showPopup: false,
      }),
    ],
  };
};
