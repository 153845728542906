import { Profiler, useEffect, useState } from "react";

import api from "service";
import { ShowPage } from "context/showPage";
import { ShowPopup } from "context/popup";
import AddView from "components/web/view/add";
import PopUp from "components/web/popup";

import { Context } from "context/data";

import Theme from "theme";

import Arrow from "assets/images/menu/arrowBack.svg";
// srcTopBar: Arrow,

export default (param) => {
  const { PageInfo, GoPage } = ShowPage();
  const { PopupShow, PopupClose } = ShowPopup();
  const { Data, Set } = Context();

  const [cityList, setCityList] = useState([]);
  const [selectedState, setSelectedState] = useState("");

  const inputStyle = {
    // maxHeight: 140,
    input: {
      border: "1px solid #bfbfbf",
      borderRadius: 4,
      padding: 8,
      fontSize: "1vw",
      maxWidth: "100%",
      justifyContent: "flex-start",
      alignItems: "flex-start",
    },
    title: Theme.text.input.title,
    select: {
      border: "1px solid #bfbfbf",
      borderRadius: 4,
      padding: 4,
      fontSize: "2vh",
    },
  };

  // useEffect(async () => {
  //   // const filterCity = await api.utils.city_name({
  //   //   query: {
  //   //     id: selectedState,
  //   //   },
  //   // });
  //   // setCityList(filterCity.data.data);
  //   console.log("lista de cidade", cityList);
  // }, [selectedState]);

  //Adicionar parceiros
  return {
    component: "container",
    style: {
      display: PageInfo.show === "pushups-add" ? "flex" : "none",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      width: "100%",
      maxHeight: "100vh",
      overflowX: "auto",
      overflowY: "auto",
      flex: 1,
      zIndex: 1,
    },
    children: [
      AddView({
        direction: "row",
        title: "Criação de novo pushup",
        srcTopBar: Arrow,
        // formData: PageInfo.param.info,
        form: {
          onSave: api.marketing.notify,
          onCancel: () => {},
          func: (data, info, clear, goBack) => {
            if (data.data?.status == true) {
              PopupShow({
                title: "Parceiro editado com sucesso!",
                description: "",
                cancelText: "",
                confirmText: "ir para lista",
                onCancel: async () => {},
                onConfirm: async () => {
                  goBack();
                },
              });
            } else {
              PopupShow({
                title: "Edição não permitida!",
                description: "",
                cancelText: "",
                style: {
                  close: {
                    display: "none",
                  },
                },
                confirmText: "voltar para lista",
                onCancel: async () => {},
                onConfirm: async () => {
                  goBack();
                },
              });
            }
          },
          style: {
            main: {
              flexWrap: "wrap",
              overflow: "auto",
              maxWidth: "50%",
              maxHeight: "62%",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              gap: 12,
              margin: "1%",
              padding: "1%",
              border: "1px solid #CBD5E1",
              borderRadius: 4,
            },
            box: {
              1: {},
            },
            btn: {
              cancel: {},
              save: {},
              style: {
                maxWidth: "51%",
              },
            },
          },
          box: {
            //Informações pessoais
            1: {
              style: {
                flex: 1,
                minWidth: "100%",
                display: "flex",
                justifyContent: "flex-start",
                flexDirection: "column",
                borderRadius: 4,
                gap: 8,
              },
              input: [
                {
                  label: "Informações Gerais",
                  style: {
                    fontSize: Theme.fontSizes.form.title,
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    color: Theme.colors.blue.life,
                    width: "100%",
                    display: "flex",
                    marginBottom: 12,
                    height: 16,
                  },
                },
                //Nome
                {
                  id: "name",
                  label: "Nome de identificação",
                  type: "input",
                  style: {
                    input: {
                      border: "1px solid #bfbfbf",
                      borderRadius: 4,
                      padding: 8,
                      fontSize: "1vw",
                      maxWidth: "100%",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      fontWeight: "600",
                    },
                  },
                  required: true,
                  // check: { min: 5 },
                },
                //Message
                {
                  id: "message",
                  label: "Mensagem",
                  type: "input",
                  maxLength: 160,
                  required: true,
                  style: inputStyle,
                },
              ],
            },
            //Filtros
            2: {
              style: {
                flex: 1,
                minWidth: "100%",
                display: "flex",
                maxHeight: 128,
                flexDirection: "column",
                borderRadius: 4,
                gap: 8,
              },
              input: [
                //label
                {
                  label: "Filtros",
                  style: {
                    fontSize: Theme.fontSizes.form.title,
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    color: Theme.colors.blue.life,
                    width: "100%",
                    display: "flex",
                    marginBottom: 12,
                    height: 16,
                  },
                },
                //div
                {
                  type: "div",
                  style: {
                    flexDirection: "row",
                  },
                  children: [
                    //por pet
                    {
                      id: "pet",
                      keyid: "filter.animal_category",
                      label: "Por tipo de pet",
                      type: "select",
                      option: api.utils.animal_category,
                      style: inputStyle,
                      required: true,
                      // check: { min: 5 },
                    },
                    //por estado
                    {
                      id: "state",
                      keyid: "filter.address_state",
                      label: "Por estado",
                      option: api.utils.state_name,
                      type: "select",
                      style: {
                        ...inputStyle,
                      },
                      required: true,
                      func: async (e) => {
                        // console.log("ver estado", e);
                        setSelectedState(e._id);
                        const filterCity = await api.utils.city_name({
                          query: {
                            id: e._id,
                          },
                        });
                        setCityList(filterCity.data.data);
                        console.log("list", cityList);
                      },
                      //check: { min: 5 },
                    },
                    //por cidade
                    {
                      id: "city",
                      keyid: "filter.address_city",
                      label: "Por cidade",
                      option: cityList,
                      update: cityList,
                      type: "select",
                      style: {
                        ...inputStyle,
                      },
                      required: true,
                      // check: { min: 5 },
                    },
                    //por situação
                    {
                      id: "situation",
                      keyid: "filter.situation",
                      label: "Por situação",
                      type: "select",
                      option: api.utils.marketing_situation,
                      style: {
                        ...inputStyle,
                      },
                      required: true,
                      // check: { min: 5 },
                    },
                  ],
                },
              ],
            },
          },
        },
        showPopup: false,
      }),
    ],
  };
};
