import Theme from "theme";
import React, { useState } from "react";
import Fade from "react-reveal/Fade";
import { Render } from "@8_dev/sjson-web";
import { Howl, Howler } from "howler";

import { ShowPage } from "context/showPage";
import { Context } from "context/data";

//api
import api from "service";

//components
import Input from "components/web/input";
import Button from "components/web/button";

//images
import Logo from "assets/images/logo.png";
import DogWoman from "assets/images/iconDogEpeople.svg";
import ShieldCheck from "assets/images/ShieldCheck.svg";

//sounds
import DogAudio from "assets/sounds/dog.mp3";

export default ({ props, content, setContent }) => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const { PageInfo, GoPage, GoInitPage } = ShowPage();

  const { Data, Set } = Context();

  const inputStyle = {
    input: {
      width: "100%",
      height: 44,
      border: "1px solid #bfbfbf",
      borderBottom: "1px solid #bfbfbf",
      borderRadius: 8,
      padding: 4,
      fontSize: 18,
    },
    title: {
      fontFamily: "Poppins",
      fontSize: 14,
      color: "rgba(52, 64, 84, 1)",
    },
  };

  const inputPassword = Input({
    title: "Nova senha",
    type: "password",
    value: password,
    setValue: setPassword,
    style: {
      ...inputStyle,
    },
  });

  const inputConfirmPassword = Input({
    title: "Confirmar nova senha",
    type: "password",
    value: confirmPassword,
    setValue: setConfirmPassword,
    style: {
      ...inputStyle,
    },
  });

  return {
    render: (
      <Fade>
        {Render({
          component: "container",
          style: {
            display: content === "ResetPassword" ? "flex" : "none",
            backgroundColor: "white",
            width: "28vw",
            height: "auto",
            marginTop: 32,
            borderRadius: 4,
            padding: "4%",
            border: "1px solid #E6E6E6",
            flexDirection: "column",
            gap: 28,
          },
          children: [
            //titleForm
            {
              component: "text",
              text: "Criar nova senha",
              style: {
                width: "100%",
                fontFamily: "Poppins",
                textAlign: "flex-start",
                fontWeight: "600",
                fontSize: 24,
                color: "#05332F",
              },
            },
            //inputs (pensar em um children.map de inputs)
            {
              component: "container",
              style: {
                display: "flex",
                width: "100%",
                height: "auto",
                flexDirection: "column",
                gap: 12,
              },
              children: [inputPassword, inputConfirmPassword],
            },
            //Botão enviar
            {
              component: "container",
              style: {
                display: "flex",
                width: "auto",
                height: "auto",
              },
              children: [
                Button({
                  label: "Atualizar senha",
                  style: {
                    button: {
                      backgroundColor: "white",
                      width: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                      height: 44,
                      backgroundColor: "rgba(0, 166, 96, 1)",
                      borderRadius: 6,
                      border: "none",
                    },
                    label: {
                      fontFamily: Theme.fontFamilies.primary,
                      fontSize: 18,
                      color: "White",
                    },
                  },
                  onPress: async () => {
                    const { data } = await api.auth.login({});
                    if (data?.status === true) {
                    } else {
                    }
                  },
                }),
              ],
            },
          ],
        })}
      </Fade>
    ),
  };
};
