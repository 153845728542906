import { Render } from "@8_dev/sjson-web";
import theme from "theme";
import api from "service";

import { useEffect, useState } from "react";
import { format, add } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";

export default function ({
  id,
  target,
  mode,
  events,
  card,
  maxDate,
  minDate,
  onChange,
  onSelect,
  dateCount,
}) {
  const [targetId, setTargetId] = useState(0);

  useEffect(() => {
    if (targetId !== id) {
      setDate(target);
      setTargetId(id);
    } else {
      setTargetId(0);
    }
  }, [id]);

  useEffect(() => {}, []);

  if (!card) card = <div>ola</div>;

  if (!target) target = new Date();
  if (!maxDate) maxDate = add(target, { days: 3 });
  if (!minDate) minDate = add(target, { days: -3 });
  const today = new Date();

  if (!events) events = [];

  if (!mode) mode = "day"; // day, week, month, year

  const [selectDateDay, setSelectDateDay] = useState(target.getDate() || 16);
  const [selectDateMonth, setSelectDateMonth] = useState(
    target.getMonth() || 9
  );
  const [selectDateYear, setSelectDateYear] = useState(
    target?.getFullYear() || 2023
  );
  const [date, setDate] = useState(new Date());

  const [selectDate, setSelectDate] = useState();

  const months = [
    {
      name: "Janeiro",
      initials: "Jan",
      index: 0,
      totalDays: new Date(target.getYear(), 1, 0).getDate(),
    },
    {
      name: "Fevereiro",
      initials: "Fev",
      index: 1,
      totalDays: new Date(target.getYear(), 2, 0).getDate(),
    },
    {
      name: "Março",
      initials: "Mar",
      index: 2,
      totalDays: new Date(target.getYear(), 3, 0).getDate(),
    },
    {
      name: "Abril",
      initials: "Abr",
      index: 3,
      totalDays: new Date(target.getYear(), 4, 0).getDate(),
    },
    {
      name: "Maio",
      initials: "Mai",
      index: 4,
      totalDays: new Date(target.getYear(), 5, 0).getDate(),
    },
    {
      name: "Junho",
      initials: "Jun",
      index: 5,
      totalDays: new Date(target.getYear(), 6, 0).getDate(),
    },
    {
      name: "Julho",
      initials: "Jul",
      index: 6,
      totalDays: new Date(target.getYear(), 7, 0).getDate(),
    },
    {
      name: "Agosto",
      initials: "Ago",
      index: 7,
      totalDays: new Date(target.getYear(), 8, 0).getDate(),
    },
    {
      name: "Setembro",
      initials: "Set",
      index: 8,
      totalDays: new Date(target.getYear(), 9, 0).getDate(),
    },
    {
      name: "Outubro",
      initials: "Out",
      index: 9,
      totalDays: new Date(target.getYear(), 10, 0).getDate(),
    },
    {
      name: "Novembro",
      initials: "Nov",
      index: 10,
      totalDays: new Date(target.getYear(), 11, 0).getDate(),
    },
    {
      name: "Dezembro",
      initials: "Dez",
      index: 11,
      totalDays: new Date(target.getYear(), 12, 0).getDate(),
    },
  ];

  const weeks = [
    { name: "Segunda", initials: "Seg", index: 0 },
    { name: "Terca", initials: "Ter", index: 1 },
    { name: "Quarta", initials: "Qua", index: 2 },
    { name: "Quinta", initials: "Qui", index: 3 },
    { name: "Sexta", initials: "Sex", index: 4 },
    { name: "Sabado", initials: "Sab", index: 5 },
    { name: "Domingo", initials: "Dom", index: 6 },
  ];

  /* // colocar seletor de data com range em dias

  // buscar as horas dis;ponieveis naquela data
  // trazer horaraio ja com agendamento
  // mostrar resumo das datas

  // criar modelo de calendario */

  const change = async (value) => {
    // if (mode==='day')

    const resDate = add(date, { days: value });

    if (resDate.getTime() < minDate.getTime()) return;
    if (resDate.getTime() > maxDate.getTime()) return;
    if (value < 0 && resDate <= today) return;

    setDate(resDate);

    try {
      onChange(resDate);
    } catch (e) {
      console.log("onChange error", e);
    }
  };

  useEffect(async () => {
    change(selectDateDay);
  }, []);

  return (
    <div
      style={{
        width: "100%",
        flex: 1,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {/* Nav title Date */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
          gap: 32,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: 5,
            opacity: "0.3",

            fontSize: 16,
            fontFamily: "Poppins",
            fontWeight: 800,
            color: "#2EC4B6",

            padding: 16,
            backgroundColor: "rgba(100,100,100,0.1)",
            borderWidth: 1,
            borderColor: "rgba(100,100,100,0.5)",
            borderRadius: 16,
          }}
        >
          <p>
            {format(add(date, { days: -1 }), "dd/MM/yyyy", {
              locale: ptBR,
              timeZone: "America/Sao_Paulo",
            })}
          </p>

          <p
            style={{
              fontSize: 10,
              fontFamily: "Poppins",
              fontWeight: 400,
            }}
          >
            {" "}
            {format(add(date, { days: -1 }), "EEEE", {
              locale: ptBR,
              timeZone: "America/Sao_Paulo",
            })}
          </p>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: 0,
            marginRight: 0,
            flex: 0.4,
            gap: 24,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              padding: 8,
              backgroundColor: "rgba(100,100,100,0.1)",
              borderWidth: 1,
              borderColor: "rgba(100,100,100,0.5)",
              borderRadius: 16,
              aspectRatio: 1 / 1,
              width: 32,
              height: 32,
              fontSize: 24,
              fontFamily: "Poppins",
              fontWeight: 400,
              zIndex: 999,
            }}
            onClick={async (e) => {
              change(-1);
            }}
          >
            {"<"}
          </div>

          {/*  Selected Date */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              marginLeft: 0,
              marginRight: 0,
              flex: 0.3,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: 5,
                fontSize: 20,
                fontFamily: "Poppins",
                fontWeight: 800,
                color: "#2EC4B6",
                padding: 16,
                backgroundColor: "rgba(100,100,100,0.1)",
                borderWidth: 1,
                borderColor: "rgba(100,100,100,0.5)",
                borderRadius: 16,
                cursor: "pointer",
              }}
              onClick={async (e) => {
                onSelect(date);
              }}
            >
              <p>
                {format(date, "dd/MM/yyyy", {
                  locale: ptBR,
                  timeZone: "America/Sao_Paulo",
                })}
              </p>
              <p
                style={{
                  fontSize: 16,
                  fontFamily: "Poppins",
                  fontWeight: 400,
                }}
              >
                {" "}
                {format(date, "EEEE", {
                  locale: ptBR,
                  timeZone: "America/Sao_Paulo",
                })}
              </p>
            </div>

            {/* // counter bal notify */}
            {Render({
              component: "container",
              style: {
                position: "absolute",
                aspectRatio: "1/1",
                backgroundColor: theme.colors.pink1,
                borderRadius: 8,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: 24,
                marginLeft: 0,
                marginTop: -12,
              },
              children: [
                {
                  component: "text",
                  text: dateCount[date.getTime()]?.sum || 0,
                  style: {
                    color: "#fff",
                    fontSize: 14,
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                  },
                },
              ],
            })}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              padding: 8,
              backgroundColor: "rgba(100,100,100,0.15)",
              borderWidth: 1,
              borderColor: "rgba(100,100,100,0.5)",
              borderRadius: 16,
              aspectRatio: 1 / 1,
              width: 32,
              height: 32,

              fontSize: 24,
              fontFamily: "Poppins",
              fontWeight: 400,
              zIndex: 999,
            }}
            onClick={async (e) => {
              change(1);
            }}
          >
            {">"}
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: 5,
            // opacity: "0.3",

            fontSize: 16,
            fontFamily: "Poppins",
            fontWeight: 800,
            color: "#2EC4B6",

            padding: 16,
            backgroundColor: "rgba(100,100,100,0.1)",
            borderWidth: 1,
            borderColor: "rgba(100,100,100,0.5)",
            borderRadius: 16,
          }}
        >
          <p>
            {format(add(date, { days: 1 }), "dd/MM/yyyy", {
              locale: ptBR,
              timeZone: "America/Sao_Paulo",
            })}
          </p>

          <p
            style={{
              fontSize: 10,
              fontFamily: "Poppins",
              fontWeight: 400,
            }}
          >
            {" "}
            {format(add(date, { days: 1 }), "EEEE", {
              locale: ptBR,
              timeZone: "America/Sao_Paulo",
            })}
          </p>
        </div>
      </div>
    </div>
  );
}
